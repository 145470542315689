import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as DropdownIcon } from '../assets/dropdown_square.svg';
import { ReactComponent as AppleIcon } from '../assets/apple.svg';
import { ReactComponent as DownloadIcon } from '../assets/download.svg';
import { ReactComponent as RightArrowIcon } from '../assets/right_arrow.svg';
import './styles.css';
import { detectOS, displayString, OperatingSystem } from './operatingSystem';
import {
  LinuxDeb,
  linuxPackages,
  LinuxRpm,
  MacDmg,
  PackageFormat,
} from './packageFormat';

/** Downloads the given `packageType`. */
function downloadPackage(packageType: PackageFormat) {
  window.rudderanalytics.track('Downloaded app', {
    packageFormat: packageType.queryParameter,
  });
  window.location.replace(`/download?package=${packageType.queryParameter}`);
}

function redirectToWindowsWaitlist() {
  window.location.replace(`https://warp.dev/windows-terminal`);
}

/** Helper react component that renders a row of download buttons for .deb and .rpm package formats. */
const LinuxButtons = () => {
  const packages = [LinuxDeb, LinuxRpm];

  const packageElements = packages.map((packageFormat, index) => {
    const btnClass = classNames(
      'dl-button',
      'text-white',
      'text-large',
      'border-radius-all-6',
      'padding-left-16',
      {
        'margin-left-8': index !== 0,
      }
    );

    return (
      <button
        type="button"
        key={packageFormat.queryParameter}
        className={btnClass}
        onClick={() => downloadPackage(packageFormat)}
      >
        <div className="dl-button-action-text">{packageFormat.display}</div>
        <RightArrowIcon className="fill-grey-50 dl-button-icon-container margin-left-8" />
      </button>
    );
  });

  return <>{packageElements}</>;
};

/** Helper react component that renders a single button to download the Mac DMG. */
const MacButton = () => {
  return (
    <button
      type="button"
      className="dl-button text-white text-large padding-right-16"
      onClick={() => downloadPackage(MacDmg)}
    >
      <AppleIcon className="fill-white dl-button-icon-container" />
      <div className="margin-left-12 dl-button-action-text">
        Download for Mac
      </div>
    </button>
  );
};

type DownloadDropdownMenuItemProps = {
  /** The name of the package format that this menu item references. */
  packageFormat: PackageFormat;

  /** Whether the name of the OS that this package format is for is rendered within the menu item. */
  isOSNameVisible: boolean;

  /**
   * Whether the package name should be rendered in this menu item. For ex: when rendering the Mac menu item we do not
   * include `.dmg` within the menu item.
   */
  includePackageName: boolean;
};

/** A specific item rendered within the download dropdown menu. */
const DownloadDropdownMenuItem = ({
  packageFormat,
  isOSNameVisible,
  includePackageName = true,
}: DownloadDropdownMenuItemProps) => {
  const packageFormatClass = classNames({ hidden: !isOSNameVisible });
  return (
    <div className="download-item download-item-package">
      <div className={packageFormatClass}>
        {displayString(packageFormat.operatingSystem)}
      </div>
      <div className="download-item-button-wrapper">
        <div className="download-item-package-name">
          {includePackageName && <div>{packageFormat.display}</div>}
          {includePackageName && (
            <div className="text-12 text-grey-50">{packageFormat.subtitle}</div>
          )}
        </div>

        <button
          type="button"
          className="download-item-button"
          onClick={() => downloadPackage(packageFormat)}
        >
          <DownloadIcon className="fill-grey-50 margin-left-12 dl-icon" />
        </button>
      </div>
    </div>
  );
};

/** Component responsible for rendering the dropdown menu. */
const DropdownMenu = () => {
  const linuxPackageElems = linuxPackages.map((packageFormat, index) => {
    return (
      <DownloadDropdownMenuItem
        packageFormat={packageFormat}
        isOSNameVisible={index === 0}
        includePackageName
      />
    );
  });

  return (
    <div className="dl-dropdown-menu border-grey-1px font-main text-16 text-grey-50">
      <DownloadDropdownMenuItem
        packageFormat={MacDmg}
        isOSNameVisible
        includePackageName={false}
      />
      <div className="border-grey-1px" />
      {linuxPackageElems}
      <button
        type="button"
        className="download-item text-grey-50 download-item download-item-windows text-14"
        onClick={() => redirectToWindowsWaitlist()}
      >
        <div>Warp for Windows is coming soon.</div>
        <div className="font-weight-500">Sign up for the waitlist</div>
      </button>
    </div>
  );
};

/**
 * Renders a download dropdown button component with a dropdown button allowing the user to pick the exact package
 * format they'd like to download.
 */
const DownloadDropdown = () => {
  const os = detectOS();

  const [isOpen, setIsOpen] = useState(false);

  const innerButtonContent =
    os === OperatingSystem.Linux ? <LinuxButtons /> : <MacButton />;

  return (
    <div className="dl-dropdown">
      <div className="dl-button-container font-main">
        <div className="button-row">
          <div className="button-row">{innerButtonContent}</div>
        </div>
        <button
          type="button"
          className="dl-dropdown-button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <DropdownIcon className="fill-grey-80 dl-dropdown-icon" />
        </button>
      </div>
      {isOpen && <DropdownMenu />}
    </div>
  );
};

export default DownloadDropdown;
