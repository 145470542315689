import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * React component that redirects to a desktop URL using Meta refresh
 *
 * https://en.wikipedia.org/wiki/Meta_refresh
 */
export default function DesktopRedirect({ url }: { url: string }) {
  return (
    <Helmet>
      <meta httpEquiv="refresh" content={`0; URL=${url}`} />
    </Helmet>
  );
}
