import { gql } from '@apollo/client';

export default gql`
  query GetUserTeams {
    user {
      teams {
        name
        uid
      }
    }
  }
`;
