import React from 'react';
import './upgrade.css';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import {
  ModalContainerIconType,
  renderIcon,
} from '../modal-container/ModalContainer';

export interface UpgradeModalContainerProps {
  iconType?: ModalContainerIconType;
  header: JSX.Element;
  children: React.ReactNode;
}

const UpgradeModalContainer = ({
  iconType,
  header,
  children,
}: UpgradeModalContainerProps) => {
  return (
    <div className="upgrade-modal-container">
      <div className="upgrade-modal-container-background" />
      <div className="upgrade-modal-container-contents font-main">
        {iconType && renderIcon(iconType)}
        <ModalContainerHeader>{header}</ModalContainerHeader>
        <ModalContainerBody>
          <div className="upgrade-modal-container-body">{children}</div>
        </ModalContainerBody>
      </div>
    </div>
  );
};

UpgradeModalContainer.defaultProps = {
  iconType: undefined,
};

export default UpgradeModalContainer;
