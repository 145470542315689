import React from 'react';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
  ModalContainerButtonType,
} from '../modal-container/ModalContainerButton';
import './upgrade.css';
import { ReactComponent as RightArrowIcon } from '../assets/right_arrow_v2.svg';

export interface CheckoutButtonProps {
  label: string;
  onClickFunction: () => void;
}

const CheckoutButton = ({ label, onClickFunction }: CheckoutButtonProps) => {
  return (
    <ModalContainerButton
      content={
        <>
          {label} <RightArrowIcon />
        </>
      }
      buttonType={ModalContainerButtonType.Button}
      treatment={ModalContainerButtonTreatment.FullWidth}
      accent={ModalContainerButtonAccent.Primary}
      onClickFunction={onClickFunction}
    />
  );
};

export default CheckoutButton;
