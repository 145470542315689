import React, { useEffect } from 'react';

interface ToastProps {
  show: boolean;
  hideToast: () => void;
}

const Toast = ({ show, hideToast }: ToastProps) => {
  useEffect(() => {
    const toast = document.getElementsByClassName('toast')[0];
    if (show) {
      toast.className += '-show';
      setTimeout(() => {
        toast.className = toast.className.replace('-show', '');
        hideToast();
      }, 1000);
    }
  }, [show, hideToast]);

  return (
    <div className="toast-container">
      <div className="toast">Copied</div>
    </div>
  );
};

export default Toast;
