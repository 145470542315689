import React from 'react';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
} from '../modal-container/ModalContainerButton';
import { AuthFormProviderButtonType } from './auth-helper';
import { ReactComponent as GitHubLogo } from '../assets/github_logo.svg';
import { ReactComponent as GoogleLogo } from '../assets/google_logo.svg';

interface AuthFormProviderButtonProps {
  providerType: AuthFormProviderButtonType;
  onClickFunction: () => Promise<void>;
}

const AuthFormProviderButton = ({
  providerType,
  onClickFunction,
}: AuthFormProviderButtonProps) => {
  let providerName;
  let ProviderIcon;

  switch (providerType) {
    case AuthFormProviderButtonType.GitHub:
      providerName = 'GitHub';
      ProviderIcon = GitHubLogo;
      break;
    case AuthFormProviderButtonType.Google:
      providerName = 'Google';
      ProviderIcon = GoogleLogo;
      break;
    default:
      providerName = 'SSO';
  }

  return (
    <div className="auth-form-provider">
      <ModalContainerButton
        content={
          <>
            {ProviderIcon && <ProviderIcon />}
            Continue with {providerName}
          </>
        }
        treatment={ModalContainerButtonTreatment.FullWidth}
        accent={ModalContainerButtonAccent.Secondary}
        onClickFunction={onClickFunction}
      />
    </div>
  );
};

export default AuthFormProviderButton;
