import React from 'react';
import ModalContainerButton, {
  ModalContainerButtonTreatment,
} from '../modal-container/ModalContainerButton';

const TermsOfService = () => {
  return (
    <div style={{ fontSize: '12px' }}>
      By continuing, you are agreeing to our{' '}
      <ModalContainerButton
        content={<>Terms of Service</>}
        treatment={ModalContainerButtonTreatment.Inline}
        href="https://www.warp.dev/terms-of-service"
        openInNewTab
      />{' '}
      and that Warp can collect usage data (only metadata, never console input
      or output).{' '}
      <ModalContainerButton
        content={<>Learn more</>}
        treatment={ModalContainerButtonTreatment.Inline}
        href="https://www.warp.dev/privacy"
        openInNewTab
      />
    </div>
  );
};

export default TermsOfService;
