import React, { useEffect, useState } from 'react';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
  ModalContainerButtonType,
} from '../modal-container/ModalContainerButton';
import { getDefaultScheme } from '../utils/env';
import CheckoutButton from './CheckoutButton';
import {
  checkAppInstallation,
  WARP_DOWNLOAD_URL,
} from '../utils/app_detection';
import WarpError from '../WarpError';

const UpgradeRequestSentView = () => {
  const [appDetectionLoading, setAppDetectionLoading] = useState<boolean>(true);
  const [appDetected, setAppDetected] = useState<boolean>(true);

  const renderCheckoutButton = () => {
    if (appDetected) {
      return (
        <CheckoutButton
          label="Open Warp"
          onClickFunction={() => {
            window.location.replace(
              `${getDefaultScheme()}://team/desktop_redirect`
            );
          }}
        />
      );
    }
    return (
      <CheckoutButton
        label="Download Warp"
        onClickFunction={() => {
          window.location.replace(WARP_DOWNLOAD_URL);
        }}
      />
    );
  };

  useEffect(() => {
    checkAppInstallation().then((detected) => {
      setAppDetected(detected);
      setAppDetectionLoading(false);
    });
  }, []);

  if (appDetectionLoading) {
    return <WarpError error="Loading..." />;
  }

  return (
    <ModalContainer iconType={ModalContainerIconType.Logo}>
      <ModalContainerHeader>Your request has been sent</ModalContainerHeader>
      <ModalContainerBody>
        {renderCheckoutButton()}
        <ModalContainerButton
          treatment={ModalContainerButtonTreatment.FullWidth}
          buttonType={ModalContainerButtonType.Button}
          accent={ModalContainerButtonAccent.Secondary}
          content={<>Back to plans</>}
          onClickFunction={() => {
            window.location.replace('/upgrade');
          }}
        />
      </ModalContainerBody>
    </ModalContainer>
  );
};

export default UpgradeRequestSentView;
