import React from 'react';
import ModalContainerButton, {
  ModalContainerButtonTreatment,
} from '../modal-container/ModalContainerButton';

const AuthHelpView = () => {
  return (
    <div style={{ fontSize: '12px' }}>
      Having trouble logging in?{' '}
      <ModalContainerButton
        content={<>Visit our docs.</>}
        treatment={ModalContainerButtonTreatment.Inline}
        href="https://docs.warp.dev/help/troubleshooting-login-issues"
        openInNewTab
      />{' '}
      Or,{' '}
      <ModalContainerButton
        content={<>report an issue</>}
        treatment={ModalContainerButtonTreatment.Inline}
        href="https://github.com/warpdotdev/Warp/issues/new/choose"
        openInNewTab
      />
    </div>
  );
};

export default AuthHelpView;
