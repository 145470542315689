import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';
import {
  mapCustomerTypeToEnum,
  TeamInfoForUpgradeView,
} from '../queries/GetUser';
import { AllowedCustomerTypes } from '../../upgrade-view/UpgradeEnums';

interface CreateTeamVariables {
  name: string;
  entrypoint: string;
}

export default () => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      mutation CreateTeam(
        $input: CreateTeamInput!
        $requestContext: RequestContext!
      ) {
        createTeam(input: $input, requestContext: $requestContext) {
          ... on CreateTeamOutput {
            workspace {
              teams {
                uid
                adminUid
                members {
                  email
                  uid
                }
              }
              billingMetadata {
                customerType
              }
            }
          }
          ... on UserFacingError {
            error {
              message
            }
          }
        }
      }
    `;

    const parseData = (data: any): TeamInfoForUpgradeView[] => {
      if (!data?.createTeam?.workspace?.teams) {
        return [];
      }

      const { workspace } = data.createTeam;
      const { billingMetadata } = workspace;
      const adminEmail = workspace.teams[0].members.find(
        (member: any) => member.uid === workspace.teams[0].adminUid
      )?.email;
      const customerType =
        mapCustomerTypeToEnum(billingMetadata?.customerType) ??
        AllowedCustomerTypes.FREE;

      return workspace.teams.map((team: any) => {
        const teamInfo: TeamInfoForUpgradeView = {
          uid: team.uid,
          adminUid: team.adminUid,
          customerType,
          members: team.members,
          adminEmail,
        };

        return teamInfo;
      });
    };

    return {
      query,
      createVariables: (variables: CreateTeamVariables) => ({
        input: variables,
        requestContext: getRequestContext(),
      }),
      parseData,
    };
  }

  const query = gql`
    mutation CreateTeam($input: CreateTeamInput!) {
      createTeamV2(input: $input) {
        __typename
        ... on CreateTeamOutput {
          team {
            uid
            creatorFirebaseUid
            billingMetadata {
              customerType
            }
            members {
              email
              firebase_uid
            }
          }
        }
        ... on UserFacingError {
          error {
            message
          }
        }
      }
    }
  `;

  const parseData = (data: any): TeamInfoForUpgradeView[] => {
    if (!data?.createTeamV2?.team) {
      return [];
    }

    const { team } = data.createTeamV2;
    const adminEmail = team.members.find(
      (member: { firebase_uid: string; email: string }) =>
        member.firebase_uid === team.creatorFirebaseUid
    )?.email;
    const customerType =
      mapCustomerTypeToEnum(team.billingMetadata?.customerType) ??
      AllowedCustomerTypes.FREE;
    const members = team.members.map(
      (member: { firebase_uid: string; email: string }) => ({
        uid: member.firebase_uid,
        email: member.email,
      })
    );

    return [
      {
        uid: team.uid,
        adminUid: team.creatorFirebaseUid,
        customerType,
        members,
        adminEmail,
      },
    ];
  };

  return {
    query,
    createVariables: (variables: CreateTeamVariables) => ({
      input: variables,
    }),
    parseData,
  };
};
