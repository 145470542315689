import { gql } from '@apollo/client';

export default gql`
  mutation teamPlanUpdateConfirmationPage(
    $input: TeamPlanUpdateConfirmationPageInput!
  ) {
    teamPlanUpdateConfirmationPage(input: $input) {
      __typename
      ... on TeamPlanUpdateConfirmationPageSuccess {
        url
      }
      ... on UserFacingError {
        error {
          message
        }
      }
    }
  }
`;
