import React from 'react';
import './team-discovery.css';
import { useApolloClient, useMutation } from '@apollo/client';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
} from '../modal-container/ModalContainerButton';
import JoinTeamWithTeamDiscovery from '../graphql/mutations/JoinTeamWithTeamDiscovery';

export interface DiscoverableTeam {
  teamUid: string;
  numMembers: number;
  name: string;
  teamAcceptingInvites: boolean;
}

interface TeamDiscoveryBoxProps {
  goToNextPage: () => void;
  team: DiscoverableTeam;
}

const TeamDiscoveryBox = ({ goToNextPage, team }: TeamDiscoveryBoxProps) => {
  const client = useApolloClient();
  const [joinTeamWithTeamDiscovery] = useMutation(JoinTeamWithTeamDiscovery, {
    client,
    // onError(error) { TODO: design error state into user flow },
  });

  const formatTeammates = (): string => {
    const { numMembers } = team;
    return numMembers && numMembers > 1
      ? `${numMembers} teammates`
      : '1 teammate';
  };

  const onClickToJoinTeam = async () => {
    await joinTeamWithTeamDiscovery({
      variables: {
        teamUid: team.teamUid,
        entrypoint: 'WebSignup',
      },
    });
    goToNextPage();
  };

  return (
    <div className="team-discovery-box">
      <div className="team-discovery-header">{team.name}</div>
      <div className="team-discovery-body">{formatTeammates()}</div>
      <div className="team-discovery-body">
        Join this team and start collaborating on workflows, notebooks, and
        more.
      </div>
      {team.teamAcceptingInvites ? (
        <ModalContainerButton
          content={<>Join</>}
          treatment={ModalContainerButtonTreatment.FullWidth}
          accent={ModalContainerButtonAccent.Primary}
          onClickFunction={() => onClickToJoinTeam()}
        />
      ) : (
        <ModalContainerButton
          disabled
          content={<>Contact Admin to request access</>}
          treatment={ModalContainerButtonTreatment.FullWidth}
          accent={ModalContainerButtonAccent.Transparent}
        />
      )}
    </div>
  );
};

export default TeamDiscoveryBox;
