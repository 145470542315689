import React from 'react';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import InviterHeading, { Inviter } from './InviterHeading';

export interface ContactAdminToUpgradeProps {
  inviterFromInviteCode: Inviter | undefined;
  teamNameFromInviteCode: string | undefined;
}

const ContactAdminToUpgrade = ({
  inviterFromInviteCode,
  teamNameFromInviteCode,
}: ContactAdminToUpgradeProps) => {
  return (
    <ModalContainer iconType={ModalContainerIconType.Warning}>
      <ModalContainerHeader>
        <InviterHeading
          inviter={inviterFromInviteCode}
          teamName={teamNameFromInviteCode}
        />
      </ModalContainerHeader>
      <ModalContainerBody>
        <div>
          The{' '}
          <span className="font-weight-600 text-white">
            {teamNameFromInviteCode}
          </span>{' '}
          team currently has the maximum number of seats for its billing plan.
        </div>
        <div>Please contact the team administrator.</div>
      </ModalContainerBody>
    </ModalContainer>
  );
};

export default ContactAdminToUpgrade;
