import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';

export default () => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      query GetUserSettings($requestContext: RequestContext!) {
        user(requestContext: $requestContext) {
          ... on UserOutput {
            user {
              settings {
                isTelemetryEnabled
              }
            }
          }
        }
      }
    `;

    const vars = {
      requestContext: getRequestContext(),
    };

    return {
      query,
      variables: vars,
      parseData: (data: any) =>
        !!data?.user?.user?.settings?.isTelemetryEnabled,
    };
  }

  const query = gql`
    query GetUserSettings {
      user {
        user_settings {
          isTelemetryEnabled
        }
      }
    }
  `;

  return {
    query,
    variables: {},
    parseData: (data: any) => !!data?.user?.user_settings?.isTelemetryEnabled,
  };
};
