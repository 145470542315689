import { gql } from '@apollo/client';
import { StripeSubscriptionInterval } from '../../upgrade-view/UpgradeEnums';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';

interface TeamPlanUpdateConfirmationPageVariables {
  teamUid: string | undefined;
  interval: StripeSubscriptionInterval;
}

export default () => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      mutation TeamPlanUpdateConfirmationPage(
        $input: TeamPlanUpdateConfirmationPageInput!
        $requestContext: RequestContext!
      ) {
        teamPlanUpdateConfirmationPage(
          requestContext: $requestContext
          input: $input
        ) {
          ... on TeamPlanUpdateConfirmationPageOutput {
            url
          }
          ... on UserFacingError {
            error {
              message
            }
          }
        }
      }
    `;

    return {
      query,
      createVariables: (
        variables: TeamPlanUpdateConfirmationPageVariables
      ) => ({
        input: variables,
        requestContext: getRequestContext(),
      }),
      parseData: (data: any): string | undefined => {
        return data?.teamPlanUpdateConfirmationPage?.url;
      },
    };
  }

  const query = gql`
    mutation teamPlanUpdateConfirmationPage(
      $input: TeamPlanUpdateConfirmationPageInput!
    ) {
      teamPlanUpdateConfirmationPage(input: $input) {
        __typename
        ... on TeamPlanUpdateConfirmationPageSuccess {
          url
        }
        ... on UserFacingError {
          error {
            message
          }
        }
      }
    }
  `;

  return {
    query,
    createVariables: (variables: TeamPlanUpdateConfirmationPageVariables) => ({
      input: variables,
      requestContext: getRequestContext(),
    }),
    parseData: (data: any): string | undefined => {
      return data?.teamPlanUpdateConfirmationPage?.url;
    },
  };
};
