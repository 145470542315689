import { gql } from '@apollo/client';

export default gql`
  mutation stripeCheckoutSessionWithIntervalAndPlan(
    $input: StripeCheckoutSessionWithIntervalAndPlanInput!
  ) {
    stripeCheckoutSessionWithIntervalAndPlan(input: $input) {
      __typename
      ... on StripeCheckoutSessionResult {
        url
      }
      ... on UserFacingError {
        error {
          message
        }
      }
    }
  }
`;
