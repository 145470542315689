import React from 'react';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import DownloadWarpBlock from './DownloadWarpBlock';
import OpenWarpButton from './OpenWarpButton';

export interface AlreadyInCurrentTeamProps {
  appDetected: boolean;
  teamNameFromInviteCode: string | undefined;
}

const AlreadyInCurrentTeam = ({
  appDetected,
  teamNameFromInviteCode,
}: AlreadyInCurrentTeamProps) => {
  return (
    <ModalContainer iconType={ModalContainerIconType.Check}>
      <ModalContainerHeader>
        You&apos;re already in the{' '}
        <span className="font-weight-600">{teamNameFromInviteCode}</span> team.
      </ModalContainerHeader>
      <ModalContainerBody>
        <DownloadWarpBlock />
        <OpenWarpButton appDetected={appDetected} />
      </ModalContainerBody>
    </ModalContainer>
  );
};

export default AlreadyInCurrentTeam;
