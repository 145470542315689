import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';

export default () => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      query GetUserTeams($requestContext: RequestContext!) {
        user(requestContext: $requestContext) {
          ... on UserOutput {
            user {
              workspaces {
                teams {
                  name
                  uid
                }
              }
            }
          }
        }
      }
    `;

    const variables = {
      requestContext: getRequestContext(),
    };

    const parseData = (data: any) => {
      return data?.user?.user?.workspaces[0]?.teams;
    };

    return { query, variables, parseData };
  }

  const query = gql`
    query GetUserTeams {
      user {
        teams {
          name
          uid
        }
      }
    }
  `;

  const parseData = (data: any) => {
    return data?.user?.teams;
  };

  return { query, variables: {}, parseData };
};
