import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';

interface CheckoutSessionDetailsVariables {
  sessionId: string | null;
}

export default (variables: CheckoutSessionDetailsVariables) => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      query checkoutSessionDetails(
        $input: CheckoutSessionDetailsInput!
        $requestContext: RequestContext!
      ) {
        checkoutSessionDetails(input: $input, requestContext: $requestContext) {
          ... on CheckoutSessionDetailsOutput {
            plan
          }
        }
      }
    `;

    const vars = {
      input: variables,
      requestContext: getRequestContext(),
    };

    return {
      query,
      variables: vars,
    };
  }

  const query = gql`
    query checkoutSessionDetails($input: CheckoutSessionDetailsInput!) {
      checkoutSessionDetails(input: $input) {
        plan
      }
    }
  `;

  const vars = {
    input: variables,
  };

  return {
    query,
    variables: vars,
  };
};
