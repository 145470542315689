import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { User } from './AuthenticatedView';
import createRedirectAfterLoginPath from './utils/redirectAfterLogin';
import LoadingScreen from './loading-screen/LoadingScreen';
import WasmView from './WasmView';
import FullPageModal from './FullPageModal';
import RequestAccessModal from './RequestAccessModal';
import { checkAppInstallation } from './utils/app_detection';
import GetCloudObject, {
  AllowedObjectTypes,
} from './graphql/queries/GetCloudObject';

interface DriveViewProps {
  user: User;
}

function getAppLaunchURL(
  objectType: AllowedObjectTypes,
  objectUID: string
): string {
  return `${process.env.REACT_APP_DEFAULT_SCHEME}://drive/${objectType}?id=${objectUID}`;
}

function mapObjectTypeToEnum(objectType: string): AllowedObjectTypes | null {
  switch (objectType) {
    case AllowedObjectTypes.Notebook:
      return AllowedObjectTypes.Notebook;
    case AllowedObjectTypes.Workflow:
      return AllowedObjectTypes.Workflow;
    case AllowedObjectTypes.EnvironmentVariables:
      return AllowedObjectTypes.EnvironmentVariables;
    default:
      return null;
  }
}

function getObjectUID(objectSlug: string): string {
  const parts = objectSlug.split('-');
  return parts[parts.length - 1];
}

function validateResourceType(
  typename: string,
  objectType: AllowedObjectTypes
): boolean {
  if (
    typename === 'NewNotebook' &&
    objectType === AllowedObjectTypes.Notebook
  ) {
    return true;
  }

  if (typename === 'Notebook' && objectType === AllowedObjectTypes.Notebook) {
    return true;
  }

  if (typename === 'Workflow' && objectType === AllowedObjectTypes.Workflow) {
    return true;
  }

  if (
    typename === 'GenericStringObject' &&
    objectType === AllowedObjectTypes.EnvironmentVariables
  ) {
    return true;
  }

  return false;
}

const NOT_FOUND_MESSAGE = "The resource you're looking for could not be found.";

const DriveView = ({ user }: DriveViewProps) => {
  // TODO figure out the correct analytics
  window.rudderanalytics.track('Visited drive link view');
  const { pathname } = useLocation();
  const params = useParams<{ object_type: string; object_slug: string }>();
  const [appDetectionLoading, setAppDetectionLoading] = useState<boolean>(true);
  const [appDetected, setAppDetected] = useState<boolean>(true);

  const objectType = mapObjectTypeToEnum(params.object_type || '');
  const objectUID = getObjectUID(params.object_slug || '');

  const { query, variables, parseData } = GetCloudObject({ uid: objectUID });
  const { loading, error: objectError, data } = useQuery(query, {
    variables,
  });

  // Check if application is detected
  useEffect(() => {
    checkAppInstallation().then((detected) => {
      setAppDetected(detected);
      setAppDetectionLoading(false);
    });
  }, []);

  if (!user) {
    return <Navigate to={createRedirectAfterLoginPath(pathname)} replace />;
  }

  if (loading || appDetectionLoading) {
    return <LoadingScreen />;
  }

  if (objectError?.message) {
    if (objectError?.message.startsWith('Unauthorized')) {
      return <RequestAccessModal objectUID={objectUID} />;
    }
    return <FullPageModal error={NOT_FOUND_MESSAGE} />;
  }

  const parsedObject = parseData(data);

  if (
    !objectType ||
    !objectUID ||
    parsedObject.isTrashed ||
    !validateResourceType(parsedObject.typename, objectType)
  ) {
    return <FullPageModal error={NOT_FOUND_MESSAGE} />;
  }

  const splashPageMessage = `Open Warp to view ${
    objectType === AllowedObjectTypes.EnvironmentVariables
      ? 'these environment variables'
      : parsedObject.name ??
        `this ${objectType[0].toUpperCase() + objectType.slice(1)}`
  }`;

  if (objectType === AllowedObjectTypes.EnvironmentVariables) {
    return (
      <FullPageModal
        appDetected={appDetected}
        appLaunchUrl={getAppLaunchURL(objectType, objectUID)}
        message={splashPageMessage}
      />
    );
  }

  // Otherwise just return the wasm component
  return (
    <WasmView
      splashPageMessage={splashPageMessage}
      appLaunchUrl={getAppLaunchURL(objectType, objectUID)}
    />
  );
};

export default DriveView;
