import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Navigate, useParams } from 'react-router-dom';
import { User } from './AuthenticatedView';
import Warp404 from './404';
import AuthView from './auth/AuthView';
import GetTeamInfoFromInviteCode from './graphql/queries/GetTeamInfoFromInviteCode';

interface TeamLoginProps {
  user: User | undefined;
  logout: () => Promise<void>;
}

/**
 * `SignInAndJoinTeamView` allows users to view team invite and sign up for Warp.
 * On success, they will be redirected to a logged in page with a download button.
 */
const SignInAndJoinTeamViewV2 = ({ user, logout }: TeamLoginProps) => {
  const { inviteCode } = useParams<{
    inviteCode: string;
  }>();
  const [
    teamNameFromInviteCode,
    setTeamNameFromInviteCode,
  ] = useState<string>();
  const [teamInviteLinkEnabled, setTeamInviteLinkEnabled] = useState(false);

  // Check if the team code is valid.
  const {
    loading: inviteCodeQueryLoading,
    error: inviteCodeInvalid,
  } = useQuery(GetTeamInfoFromInviteCode, {
    variables: { code: inviteCode },
    onCompleted(data) {
      if (data?.getTeamInfoFromInviteCode) {
        setTeamNameFromInviteCode(data.getTeamInfoFromInviteCode.name);
        setTeamInviteLinkEnabled(
          data.getTeamInfoFromInviteCode.isInviteLinkEnabled
        );
      }
    },
  });

  if (
    !inviteCodeQueryLoading &&
    (inviteCodeInvalid || !teamNameFromInviteCode || !teamInviteLinkEnabled)
  ) {
    if (user?.isTelemetryEnabled) {
      window.rudderanalytics.track('Visited Team Signup with Invalid Code', {
        inviteCode,
      });
    }
    return <Warp404 />;
  }

  if (user) {
    return <Navigate to={`/team/${inviteCode}`} replace />;
  }

  window.rudderanalytics.track('Visited Team Signup of Team', {
    teamNameFromInviteCode,
  });

  return (
    <AuthView
      headerContent={
        <>
          You&apos;ve been invited to join the{' '}
          <span className="font-weight-600">{teamNameFromInviteCode}</span> team
          on Warp.
        </>
      }
      showLoadingState={inviteCodeQueryLoading}
      buttonTrackingObject={{
        segmentMessage: 'Click Sign Up Button on Referred Signup View',
        googleAnalyticsMessage: 'Sign Up on Referred Sign Up View',
        googleAnalyticsCategory: 'Sign Up',
      }}
      user={user}
      logout={logout}
    />
  );
};

export default SignInAndJoinTeamViewV2;
