import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  FIREBASE_APIKEY,
  FIREBASE_APPID,
  FIREBASE_AUTHDOMAIN,
  FIREBASE_MESSAGINGSENDERID,
  FIREBASE_PROJECTID,
  FIREBASE_STORAGEBUCKET,
} from '../const';

const firebaseConfig = {
  apiKey: FIREBASE_APIKEY,
  authDomain: FIREBASE_AUTHDOMAIN,
  projectId: FIREBASE_PROJECTID,
  storageBucket: FIREBASE_STORAGEBUCKET,
  messagingSenderId: FIREBASE_MESSAGINGSENDERID,
  appId: FIREBASE_APPID,
};

const firebase = initializeApp(firebaseConfig);

// This function exposes information about the logged-in user for handoff to the Warp on Web WASM
// binary.
//
// It must be a global function because the WASM app isn't set up to import Webpack modules. If we
// ever add a WASM-specific entrypoint (instead of running the app's main function), we should
// consider directly passing in the user information there.
window.warpUserHandoff = function warpUserHandoff() {
  const auth = getAuth(firebase);
  if (auth.currentUser) {
    return auth.currentUser.refreshToken;
  }
  return null;
};

export default firebase;
