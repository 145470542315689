/* eslint-disable react/jsx-no-target-blank */
import React, { useMemo } from 'react';

import Anser from 'anser';
import ReactHtmlParser from 'html-react-parser';
import { escapeCarriageReturn } from 'escape-carriage';

import { SharedBlockProps } from './block';
import BlockDropdownMenu from './BlockDropdownMenu';
import BlockHeader from './BlockHeader';
import { ReactComponent as WarpLogoWithText } from '../assets/warp_logo_with_text.svg';

function parseAnsi(input: string) {
  let transformedInput = escapeCarriageReturn(input);
  transformedInput = Anser.escapeForHtml(transformedInput);
  return (
    <div>
      {ReactHtmlParser(
        Anser.ansiToHtml(transformedInput, { use_classes: true })
      )}
    </div>
  );
}

const WarpAnsiBlock = ({ blockRenderInfo, isEmbed }: SharedBlockProps) => {
  const { block, showCommand, showOutput } = blockRenderInfo;
  const blockPromptAndCommand = useMemo(() => {
    if (block.stylizedPromptAndCommand) {
      return parseAnsi(block.stylizedPromptAndCommand);
    }
    return null;
  }, [block.stylizedPromptAndCommand]);
  const blockPrompt = useMemo(() => parseAnsi(block.stylizedPrompt), [
    block.stylizedPrompt,
  ]);
  const blockCommand = useMemo(() => parseAnsi(block.stylizedCommand), [
    block.stylizedCommand,
  ]);
  const blockOutput = useMemo(
    () =>
      block.stylizedOutput.split('\n').map((line: string) => parseAnsi(line)),
    [block.stylizedOutput]
  );

  const embedFooter = (
    <div className="embed-footer">
      <a href="https://www.warp.dev" target="_blank" rel="noopener">
        <WarpLogoWithText
          title="Warp Logo"
          className="warp-logo-embed-footer"
        />
      </a>
    </div>
  );

  return (
    <div className="block_wrapper">
      <BlockHeader block={block} isEmbed />
      <div className="sticky-header">
        <div className="prompt-command-column">
          {block.showPrompt && !blockPromptAndCommand && (
            <div className="prompt">{blockPrompt}</div>
          )}
          {showCommand && blockPromptAndCommand ? (
            <div className="prompt-and-command">{blockPromptAndCommand}</div>
          ) : (
            <div className="command">{blockCommand}</div>
          )}
        </div>
        <BlockDropdownMenu
          block={block}
          showCommand={showCommand}
          showOutput={showOutput}
          isEmbed
        />
      </div>
      {showOutput && <div className="output">{blockOutput}</div>}
      {isEmbed && embedFooter}
    </div>
  );
};

export default WarpAnsiBlock;
