import { gql } from '@apollo/client';

export default gql`
  query GetUserSettings {
    user {
      user_settings {
        isTelemetryEnabled
      }
    }
  }
`;
