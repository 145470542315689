import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';
import { Inviter } from '../../join-team/InviterHeading';

export interface TeamInfoFromInviteCodeData {
  name: string;
  uid: string;
  approvedDomains: string[];
  isInviteLinkEnabled: boolean;
  inviter: Inviter;
  teamAcceptingInvites: boolean;
}

export interface TeamInfoFromInviteCodeVariables {
  code: string | undefined;
}

export default (variables: TeamInfoFromInviteCodeVariables) => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      query GetTeamInfoFromInviteCode(
        $input: TeamInfoFromInviteCodeInput!
        $requestContext: RequestContext!
      ) {
        teamInfoFromInviteCode(input: $input, requestContext: $requestContext) {
          ... on TeamInfoFromInviteCodeOutput {
            approvedDomains
            isInviteLinkEnabled
            publicTeamInfo {
              name
              uid
              inviterEmail
              inviterDisplayName
              inviterPhotoUrl
              teamAcceptingInvites
            }
          }
        }
      }
    `;

    const vars = {
      input: {
        code: variables.code,
      },
      requestContext: getRequestContext(),
    };

    const parseData = (data: any): TeamInfoFromInviteCodeData => {
      return {
        name: data?.teamInfoFromInviteCode?.publicTeamInfo?.name,
        uid: data?.teamInfoFromInviteCode?.publicTeamInfo?.uid,
        approvedDomains: data?.teamInfoFromInviteCode?.approvedDomains,
        isInviteLinkEnabled: data?.teamInfoFromInviteCode?.isInviteLinkEnabled,
        inviter: {
          email: data?.teamInfoFromInviteCode?.publicTeamInfo?.inviterEmail,
          name:
            data?.teamInfoFromInviteCode?.publicTeamInfo?.inviterDisplayName,
          photoUrl:
            data?.teamInfoFromInviteCode?.publicTeamInfo?.inviterPhotoUrl,
        },
        teamAcceptingInvites:
          data?.teamInfoFromInviteCode?.publicTeamInfo?.teamAcceptingInvites,
      };
    };

    return { query, variables: vars, parseData };
  }

  const query = gql`
    query GetTeamInfoFromInviteCode($code: String!) {
      getTeamInfoFromInviteCode(code: $code) {
        name
        uid
        approvedDomains
        isInviteLinkEnabled
        inviterEmail
        inviterDisplayName
        inviterPhotoUrl
        teamAcceptingInvites
      }
    }
  `;

  const vars = {
    code: variables.code,
  };

  const parseData = (data: any): TeamInfoFromInviteCodeData => {
    return {
      name: data?.getTeamInfoFromInviteCode?.name,
      uid: data?.getTeamInfoFromInviteCode?.uid,
      approvedDomains: data?.getTeamInfoFromInviteCode?.approvedDomains,
      isInviteLinkEnabled: data?.getTeamInfoFromInviteCode?.isInviteLinkEnabled,
      inviter: {
        email: data?.getTeamInfoFromInviteCode?.inviterEmail,
        name: data?.getTeamInfoFromInviteCode?.inviterDisplayName,
        photoUrl: data?.getTeamInfoFromInviteCode?.inviterPhotoUrl,
      },
      teamAcceptingInvites:
        data?.getTeamInfoFromInviteCode?.teamAcceptingInvites,
    };
  };

  return { query, variables: vars, parseData };
};
