import React from 'react';
import { ModalContainerIconType } from '../modal-container/ModalContainer';
import './upgrade.css';
import UpgradeResultView from './UpgradeResultView';

const UpgradeErrorView = () => {
  return (
    <UpgradeResultView
      icon={ModalContainerIconType.Alert}
      headerText="Error"
      bodyContent={<p>Something went wrong.</p>}
    />
  );
};

export default UpgradeErrorView;
