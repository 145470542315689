import React, { useEffect, useState } from 'react';
import warpLogoWithText from './assets/warp_logo_with_text.svg';
import AuthHeaderContext from './auth/AuthHeaderContext';
import { WarpEventKind, warpEventBus } from './warp-client';
import { DiscoverableTeam } from './team-discovery/TeamDiscoveryBox';

export interface User {
  /**
   * The display name of the user. Null in the case of email login, or if the user has
   * not set it.
   */
  displayName: string | null;
  /**
   * The email of the user. Null in the case of some non-email login methods.
   */
  email: string | null;
  /**
   * The profile photo URL of the user. Null in the case of email login, or if the user has
   * not set it.
   */
  photoURL: string | null;
  /**
   * Refresh token used to reauthenticate the user. Must exist in order to log user in natively.
   */
  refreshToken: string;
  /**
   * The user's unique Firebase ID, scoped to the Firebase project. This UID is also stored in
   * our `users` table.
   */
  firebaseUID: string;
  /**
   * Whether or not the user has just signed up. For now, this is used to conditionally display
   * first-time user experiences like live onboarding and onboarding survey.
   */
  isUserNew: boolean;
  /**
   * Whether or not the user has seen the blocked based onboarding flow in the native desktop app.
   */
  isOnboarded: boolean;
  /**
   * Array of discoverable teams a user can join.
   */
  joinableTeams: DiscoverableTeam[];
  /**
   * Whether user has telemetry enabled in their settings.
   */
  isTelemetryEnabled: boolean;
  /**
   * The type of anonymous user, if any.
   */
  anonymousUserType: AnonymousUserType | null;
  /**
   *  Whether the user is anonymous.
   */
  isAnonymous: boolean;
}

export enum AnonymousUserType {
  // An anonymous user created on the native client.
  NATIVE_CLIENT,
}

export interface AuthenticatedViewProps {
  user: User;
  children: any;
  logout: () => Promise<void>;
}

// View for common elements for authenticated pages.
// So far, just a header with Warp logo and user profile dropdown.
const AuthenticatedView = ({
  user,
  logout,
  children,
}: AuthenticatedViewProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [shouldDisplayAuthViewHeader, setDisplayAuthViewHeader] = useState(
    true
  );

  // The logout button isn't shown when rendering the WoW WASM app. Instead, listen for in-app logout events,
  // and act as if the button were pressed.
  useEffect(() => {
    return warpEventBus.addListener((event) => {
      if (event.kind === WarpEventKind.LoggedOut) {
        // Refresh the page after logging out, since WoW currently can't log back in.
        logout().then((_) => window.location.reload());
      }
    });
  }, [logout]);

  function circleWithPicture(url: string) {
    return (
      <img
        className="authed-user-image"
        alt="Profile"
        src={url}
        style={{ height: 50 }}
      />
    );
  }

  // Used when the user doesn't have a profile pic associated
  // with their account.
  function circleWithLetter() {
    const displayName = user?.displayName ?? user?.email;
    const firstLetter = displayName?.charAt(0)?.toUpperCase();
    return <div className="profile-circle">{firstLetter}</div>;
  }

  function profileCircle() {
    return (
      <button
        type="button"
        style={{
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          paddingBottom: 5,
        }}
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        {user?.photoURL ? circleWithPicture(user.photoURL) : circleWithLetter()}
      </button>
    );
  }

  function userDropdown() {
    return (
      <div>
        {profileCircle()}
        {dropdownOpen && (
          <div className="user-profile-expanded">
            {user?.displayName && (
              <div className="user-profile-expanded-item">
                <b>{user.displayName}</b>
              </div>
            )}
            {user?.email && (
              <div className="user-profile-expanded-item">{user.email}</div>
            )}
            <div
              className="user-profile-expanded-item"
              style={{ paddingTop: 20 }}
            >
              <button
                type="button"
                onClick={logout}
                style={{ width: 80, height: 30 }}
                className="action-button"
              >
                Log out
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      {shouldDisplayAuthViewHeader && (
        <div className="authenticated-header">
          <a href="https://www.warp.dev">
            <img
              src={warpLogoWithText}
              className="top-left-logo"
              alt="Warp header logo"
            />
          </a>
          {user && !user.isAnonymous && userDropdown()}
        </div>
      )}
      <AuthHeaderContext.Provider value={setDisplayAuthViewHeader}>
        {children}
      </AuthHeaderContext.Provider>
    </div>
  );
};

export default AuthenticatedView;
