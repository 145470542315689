import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import {
  StripeSubscriptionInterval,
  StripeSubscriptionPlan,
} from '../../upgrade-view/UpgradeEnums';
import getRequestContext from '../get-request-context';

interface SendUpgradeRequestVariables {
  teamUid: string;
  interval: StripeSubscriptionInterval;
  plan: StripeSubscriptionPlan;
  reason: string;
}

export default () => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      mutation SendUpgradeRequest(
        $input: SendUpgradeRequestInput!
        $requestContext: RequestContext!
      ) {
        sendUpgradeRequest(requestContext: $requestContext, input: $input) {
          ... on SendUpgradeRequestOutput {
            success
          }
          ... on UserFacingError {
            error {
              message
            }
          }
        }
      }
    `;

    return {
      query,
      createVariables: (variables: SendUpgradeRequestVariables) => ({
        input: variables,
        requestContext: getRequestContext(),
      }),
      parseData: (data: any): boolean | undefined =>
        data?.sendUpgradeRequest?.success,
    };
  }

  const query = gql`
    mutation sendUpgradeRequest($input: SendUpgradeRequestInput!) {
      sendUpgradeRequest(input: $input) {
        __typename
        ... on SendUpgradeRequestSuccess {
          success
        }
        ... on UserFacingError {
          error {
            message
          }
        }
      }
    }
  `;

  return {
    query,
    createVariables: (variables: SendUpgradeRequestVariables) => ({
      input: variables,
    }),
    parseData: (data: any): boolean | undefined =>
      data?.sendUpgradeRequest?.success,
  };
};
