import React from 'react';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
  ModalContainerButtonType,
} from '../modal-container/ModalContainerButton';
import './upgrade.css';
import { StripeSubscriptionInterval } from './UpgradeEnums';

export interface IntervalToggleProps {
  inModal: boolean;
  interval: StripeSubscriptionInterval;
  monthlyOnClickFunction: () => void;
  yearlyOnClickFunction: () => void;
}

const IntervalToggle = ({
  inModal,
  interval,
  monthlyOnClickFunction,
  yearlyOnClickFunction,
}: IntervalToggleProps) => {
  const className = inModal
    ? 'upgrade-toggle-button-container-in-modal'
    : 'upgrade-toggle-button-container font-main';
  return (
    <div className={className}>
      <ModalContainerButton
        content={<>Monthly</>}
        buttonType={ModalContainerButtonType.Button}
        treatment={ModalContainerButtonTreatment.FullWidth}
        accent={
          interval === StripeSubscriptionInterval.MONTHLY
            ? ModalContainerButtonAccent.Primary
            : ModalContainerButtonAccent.Toggleable
        }
        onClickFunction={monthlyOnClickFunction}
      />
      <ModalContainerButton
        content={<>Yearly</>}
        buttonType={ModalContainerButtonType.Button}
        treatment={ModalContainerButtonTreatment.FullWidth}
        accent={
          interval === StripeSubscriptionInterval.YEARLY
            ? ModalContainerButtonAccent.Primary
            : ModalContainerButtonAccent.Toggleable
        }
        onClickFunction={yearlyOnClickFunction}
      />
    </div>
  );
};

export default IntervalToggle;
