import { gql } from '@apollo/client';

export default gql`
  query GetCloudObject($uid: ID!) {
    getCloudObject(input: { objectId: $uid }) {
      object {
        ... on NewNotebook {
          titleName
          metadata {
            trashedTs
          }
        }
        ... on Workflow {
          data
          metadata {
            trashedTs
          }
        }
        ... on GenericStringObject {
          format
          metadata {
            trashedTs
          }
        }
      }
    }
  }
`;
