import React from 'react';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import InviterHeading, { Inviter } from './InviterHeading';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
  ModalContainerButtonType,
} from '../modal-container/ModalContainerButton';

export interface JoinTeamProps {
  inviterFromInviteCode: Inviter | undefined;
  teamNameFromInviteCode: string | undefined;
  onClickFunction: () => any;
  userEmail: string | null;
}

const JoinTeam = ({
  inviterFromInviteCode,
  teamNameFromInviteCode,
  onClickFunction,
  userEmail,
}: JoinTeamProps) => {
  return (
    <ModalContainer iconType={ModalContainerIconType.Logo}>
      <ModalContainerHeader>
        <InviterHeading
          inviter={inviterFromInviteCode}
          teamName={teamNameFromInviteCode}
        />
      </ModalContainerHeader>
      <ModalContainerBody>
        You are logged in as {userEmail}.
        <ModalContainerButton
          treatment={ModalContainerButtonTreatment.FullWidth}
          buttonType={ModalContainerButtonType.Button}
          accent={ModalContainerButtonAccent.Primary}
          content={<>Join team</>}
          onClickFunction={onClickFunction}
        />
      </ModalContainerBody>
    </ModalContainer>
  );
};

export default JoinTeam;
