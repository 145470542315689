import React, { FormEvent } from 'react';
import {
  AuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider,
} from 'firebase/auth';
import './auth-form.css';
import AuthFormProviderButton from './AuthFormProviderButton';
import { AuthFormProviderButtonType } from './auth-helper';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
} from '../modal-container/ModalContainerButton';

interface AuthFormProps {
  email: string;
  setEmail: (email: string) => void;
  onEmailSubmit: () => Promise<void>;
  onSsoButtonClicked: () => Promise<void>;
  signInWithProvider: (provider: AuthProvider) => Promise<void>;
  enabledProviders: string[];
}

const AuthFlow = ({
  email,
  setEmail,
  onEmailSubmit,
  onSsoButtonClicked,
  signInWithProvider,
  enabledProviders,
}: AuthFormProps) => {
  const showEmail = enabledProviders.includes('password');
  const showGitHub = enabledProviders.includes('github.com');
  const showGoogle = enabledProviders.includes('google.com');
  const showSso = enabledProviders.includes('oidc.workos');

  const showDivider = showEmail && (showGitHub || showGoogle || showSso);

  const emailInput = showEmail && (
    <form
      className="auth-form-email-form"
      onSubmit={(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onEmailSubmit();
      }}
    >
      <div className="font-weight-600 text-white">Email</div>
      <input
        className="modal-container-input"
        type="text"
        placeholder="Your email address"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <ModalContainerButton
        content={<>Continue</>}
        treatment={ModalContainerButtonTreatment.FullWidth}
        accent={ModalContainerButtonAccent.Primary}
      />
    </form>
  );

  const divider = showDivider && (
    <div className="auth-form-divider">
      <div className="auth-form-divider-line" />
      <div className="auth-form-divider-or">OR</div>
      <div className="auth-form-divider-line" />
    </div>
  );

  const gitHubButton = showGitHub && (
    <AuthFormProviderButton
      providerType={AuthFormProviderButtonType.GitHub}
      onClickFunction={() => {
        const provider = new GithubAuthProvider();
        // Explicitly request access to the user's email address.
        provider.addScope('user:email');
        return signInWithProvider(provider);
      }}
    />
  );

  const googleButton = showGoogle && (
    <AuthFormProviderButton
      providerType={AuthFormProviderButtonType.Google}
      onClickFunction={() => {
        const provider = new GoogleAuthProvider();
        // Explicitly request access to the user's email address.
        provider.addScope('email');
        return signInWithProvider(provider);
      }}
    />
  );

  const ssoButton = showSso && (
    <AuthFormProviderButton
      providerType={AuthFormProviderButtonType.Sso}
      onClickFunction={onSsoButtonClicked}
    />
  );

  return (
    <div className="auth-form">
      {emailInput}
      {divider}
      {gitHubButton}
      {googleButton}
      {ssoButton}
    </div>
  );
};

export default AuthFlow;
