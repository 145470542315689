import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import WasmComponent from './WasmComponent';
import FullPageModal from './FullPageModal';
import AuthHeaderContext from './auth/AuthHeaderContext';
import LoadingScreen from './loading-screen/LoadingScreen';
import { OpenOnNativeEvent, WarpEventKind, warpEventBus } from './warp-client';
import { checkWoWStatus } from './warp-client/requirements';
import UnsupportedModal from './warp-client/UnsupportedModal';
import { checkAppInstallation } from './utils/app_detection';

// NOTE: This string much rename in sync with the one that defines the setting in warp-internal
// https://github.com/warpdotdev/warp-internal/blob/86f45a40e9b0dd29fe98f40a2c02d33805d8be22/app/src/settings/native_preference.rs#L20-L21
const USER_NATIVE_PREFERENCE_KEY = 'UserNativePreference';

enum UserNativePreference {
  NotSelected = 'NotSelected',
  Web = 'Web',
  Desktop = 'Desktop',
}

interface WasmViewProps {
  splashPageMessage: string;
  appLaunchUrl: string;
  redirectUrl?: string;
}

const WasmView = ({
  splashPageMessage,
  appLaunchUrl,
  redirectUrl,
}: WasmViewProps) => {
  const setDisplayAuthViewHeader = useContext(AuthHeaderContext);
  const [shouldDirectToDesktop, setShouldDirectToDesktop] = useState(true);

  const [nativePreferenceIsLoading, setNativePreferenceIsLoading] = useState(
    true
  );
  const [appDetected, setAppDetected] = useState<boolean>(true);
  const [
    appInstallationDetectLoading,
    setAppInstallationDetectLoading,
  ] = useState(true);

  useEffect(() => {
    // Try to read the user app installation status from local storage
    checkAppInstallation().then((detected) => {
      setAppDetected(detected);
      setAppInstallationDetectLoading(false);
    });
  }, []);

  useEffect(() => {
    let userNativePreference = UserNativePreference.NotSelected;
    const userNativePreferenceSetting = window.localStorage.getItem(
      USER_NATIVE_PREFERENCE_KEY
    );

    if (userNativePreferenceSetting !== null) {
      // Same logic as above for the double parse.
      const preferenceString: String = JSON.parse(
        JSON.parse(userNativePreferenceSetting)
      );
      const userNativePreferenceFromStorage =
        UserNativePreference[
          preferenceString as keyof typeof UserNativePreference
        ];

      if (userNativePreferenceFromStorage !== undefined) {
        userNativePreference = userNativePreferenceFromStorage;
      }
    }

    // If the user doesn't have the setting explicitly to desktop - then route to the web.
    // If they do have it set to desktop, this should override the app installation detection logic
    // above as the app installation flow is not 100% correct in determining the install status and
    // honoring the user's choice of redirect is more important.
    if (userNativePreference !== UserNativePreference.Desktop) {
      setShouldDirectToDesktop(false);
      setDisplayAuthViewHeader(false);
    }
    setNativePreferenceIsLoading(false);
  }, [setDisplayAuthViewHeader]);

  useEffect(() => {
    warpEventBus.addListener((event) => {
      if (event.kind === WarpEventKind.OpenOnNative) {
        window.open((event as OpenOnNativeEvent).url, '_self');
      }
    });
  }, []);

  if (nativePreferenceIsLoading || appInstallationDetectLoading) {
    return <LoadingScreen />;
  }

  if (isMobile) {
    return (
      <FullPageModal error="Please visit this link on a desktop or laptop computer with Warp installed." />
    );
  }

  // If Warp on Web will not be able to load, redirect to desktop.
  const wowStatus = checkWoWStatus();
  if (!wowStatus.supported) {
    return (
      <UnsupportedModal
        status={wowStatus}
        redirectMessage={splashPageMessage}
        appLaunchUrl={appLaunchUrl}
      />
    );
  }

  // If we want to direct to desktop
  if (shouldDirectToDesktop) {
    const viewOnWebCallback = () => {
      setShouldDirectToDesktop(false);
      setDisplayAuthViewHeader(false);
    };

    return (
      <FullPageModal
        appDetected={appDetected}
        appLaunchUrl={appLaunchUrl}
        message={splashPageMessage}
        viewOnWebCallback={viewOnWebCallback}
      />
    );
  }

  if (redirectUrl) {
    window.history.replaceState({ pathname: redirectUrl }, '', redirectUrl);
  }
  // Otherwise just return the wasm component
  return (
    <>
      <WasmComponent />
    </>
  );
};

WasmView.defaultProps = {
  redirectUrl: undefined,
};

export default WasmView;
