import React from 'react';
import './inviter-heading.css';

export interface Inviter {
  email: string;
  name: string;
  photoUrl: string | undefined;
}

export interface InviterHeadingProps {
  inviter: Inviter | undefined;
  teamName: string | undefined;
}

const InviterHeading = ({ inviter, teamName }: InviterHeadingProps) => {
  let avatar = null;

  if (inviter?.photoUrl) {
    avatar = (
      <img
        className="inviter-heading-user-image"
        alt={`${inviter?.name || inviter?.email || 'user'} profile`}
        src={inviter.photoUrl}
      />
    );
  }

  let inviterLabel = null;

  // Firebase returns an empty string if the display name is unavailable.
  if (inviter?.name) {
    inviterLabel = (
      <>
        {avatar}
        <span className="font-weight-600">{inviter?.name}</span> (
        {inviter?.email})
      </>
    );
  } else {
    inviterLabel = (
      <>
        {avatar}
        <span className="font-weight-600">{inviter?.email || 'Unknown'}</span>
      </>
    );
  }
  return (
    <>
      {inviterLabel} has invited you to join the{' '}
      <span className="font-weight-600">{teamName}</span> team on Warp.
    </>
  );
};

export default InviterHeading;
