import { gql } from '@apollo/client';

export default gql`
  mutation CreateAnonymousUser($input: CreateAnonymousUserInput!) {
    createAnonymousUser(input: $input) {
      ... on CreateAnonymousUserOutput {
        id_token
      }
    }
  }
`;
