import { gql } from '@apollo/client';

export default gql`
  query GetUser {
    user {
      email
      teams {
        uid
        creatorFirebaseUid
        billingMetadata {
          customerType
        }
        members {
          email
          firebase_uid
        }
      }
    }
  }
`;
