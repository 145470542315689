import { gql } from '@apollo/client';

export default gql`
  query GetUser {
    user {
      email
      teams {
        name
        creatorFirebaseUid
        members {
          email
        }
      }
    }
  }
`;
