enum DisplaySetting {
  COMMAND = 'COMMAND',
  OUTPUT = 'OUTPUT',
  COMMAND_AND_OUTPUT = 'COMMAND_AND_OUTPUT',
}

type Block = {
  title: string;
  stylizedPrompt: string;
  stylizedCommand: string;
  stylizedPromptAndCommand: string | null;
  stylizedOutput: string;
  command: string;
  output: string;
  showPrompt: boolean;
  embedDisplaySetting: string;
};

type SharedBlockHeaderProps = {
  block: Block;
  isEmbed: boolean;
};

type BlockRenderInfo = {
  block: Block;
  showCommand: boolean;
  showOutput: boolean;
  isEmbed: boolean;
};

type SharedBlockProps = {
  blockRenderInfo: BlockRenderInfo;
  isEmbed: boolean;
};

export { DisplaySetting };
export type {
  Block,
  SharedBlockProps,
  SharedBlockHeaderProps,
  BlockRenderInfo,
};
