import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';

interface StripeBillingPortalVariables {
  teamUid: string | undefined;
}

export default (variables: StripeBillingPortalVariables) => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      mutation StripeBillingPortal(
        $input: StripeBillingPortalInput!
        $requestContext: RequestContext!
      ) {
        stripeBillingPortal(requestContext: $requestContext, input: $input) {
          ... on StripeBillingPortalOutput {
            url
          }
          ... on UserFacingError {
            error {
              message
            }
          }
        }
      }
    `;

    const vars = {
      input: {
        teamUid: variables.teamUid,
      },
      requestContext: getRequestContext(),
    };

    return {
      query,
      variables: vars,
      parseData: (data: any): string | undefined => {
        return data?.stripeBillingPortal?.url;
      },
    };
  }

  const query = gql`
    mutation stripeBillingPortal($input: StripeBillingPortalInput!) {
      stripeBillingPortal(input: $input) {
        url
      }
    }
  `;

  const vars = {
    input: {
      teamUid: variables.teamUid,
    },
  };

  return {
    query,
    variables: vars,
    parseData: (data: any): string | undefined => {
      return data?.stripeBillingPortal?.url;
    },
  };
};
