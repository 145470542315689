import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';

interface CheckAndRecordObjectAccessVariables {
  uid: string | undefined;
}

interface ObjectAccessOutput {
  objectName: string;
  isTrashed: boolean;
  folderPrimaryObject: {
    objectType: AllowedObjectTypes | null;
    metadata: {
      uid: string;
    };
  } | null;
}

export enum AllowedObjectTypes {
  Notebook = 'notebook',
  Workflow = 'workflow',
  EnvironmentVariables = 'env-vars',
  Folder = 'folder',
}

function mapResourceTypeToObjectType(
  resourceType: string
): AllowedObjectTypes | null {
  switch (resourceType) {
    // In the V1 API, notebooks are called "NewNotebook"
    case 'NewNotebook':
      return AllowedObjectTypes.Notebook;
    // In the V2, they're just called "Notebook"
    case 'Notebook':
      return AllowedObjectTypes.Notebook;
    case 'Workflow':
      return AllowedObjectTypes.Workflow;
    case 'Folder':
      return AllowedObjectTypes.Folder;
    case 'GenericStringObject':
      return AllowedObjectTypes.EnvironmentVariables;
    default:
      return null;
  }
}

export default (variables: CheckAndRecordObjectAccessVariables) => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      mutation CheckAndRecordObjectAccess(
        $input: CheckAndRecordObjectAccessInput!
        $requestContext: RequestContext!
      ) {
        checkAndRecordObjectAccess(
          input: $input
          requestContext: $requestContext
        ) {
          ... on CheckAndRecordObjectAccessOutput {
            objectName
            isTrashed
            folderPrimaryObject {
              ... on Notebook {
                __typename
                metadata {
                  uid
                }
              }
              ... on Workflow {
                __typename
                metadata {
                  uid
                }
              }
            }
          }
        }
      }
    `;

    const vars = {
      input: {
        uid: variables.uid,
      },
      requestContext: getRequestContext(),
    };

    return {
      query,
      variables: vars,
      parseData: (data: any): ObjectAccessOutput => {
        const folderPrimaryObject = data.checkAndRecordObjectAccess
          .folderPrimaryObject
          ? {
              objectType: mapResourceTypeToObjectType(
                // eslint-disable-next-line no-underscore-dangle
                data.checkAndRecordObjectAccess.folderPrimaryObject.__typename
              ),
              metadata: {
                uid:
                  data.checkAndRecordObjectAccess.folderPrimaryObject.metadata
                    .uid,
              },
            }
          : null;

        return {
          objectName: data.checkAndRecordObjectAccess.objectName,
          isTrashed: data.checkAndRecordObjectAccess.isTrashed,
          folderPrimaryObject,
        };
      },
    };
  }

  const query = gql`
    mutation CheckAndRecordObjectAccess($uid: ID!) {
      checkAndRecordObjectAccess(input: { objectUID: $uid }) {
        ... on CheckAndRecordObjectAccessOutput {
          objectName
          isTrashed
          folderPrimaryObject {
            ... on NewNotebook {
              __typename
              metadata {
                UID
              }
            }
            ... on Workflow {
              __typename
              metadata {
                UID
              }
            }
          }
        }
      }
    }
  `;

  const vars = {
    uid: variables.uid,
  };

  return {
    query,
    variables: vars,
    parseData: (data: any): ObjectAccessOutput => {
      const folderPrimaryObject = data.checkAndRecordObjectAccess
        .folderPrimaryObject
        ? {
            objectType: mapResourceTypeToObjectType(
              // eslint-disable-next-line no-underscore-dangle
              data.checkAndRecordObjectAccess.folderPrimaryObject.__typename
            ),
            metadata: {
              uid:
                data.checkAndRecordObjectAccess.folderPrimaryObject.metadata
                  .UID,
            },
          }
        : null;

      return {
        objectName: data.checkAndRecordObjectAccess.objectName,
        isTrashed: data.checkAndRecordObjectAccess.isTrashed,
        folderPrimaryObject,
      };
    },
  };
};
