import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import Warp404 from './404';
import { User } from './AuthenticatedView';
import AlreadyInCurrentTeam from './join-team/AlreadyInCurrentTeam';
import AlreadyInOtherTeam from './join-team/AlreadyInOtherTeam';
import ExpiredInvite from './join-team/ExpiredInvite';
import JoinTeam from './join-team/JoinTeam';
import SuccessfulJoin from './join-team/SuccessfulJoin';
import WrongEmailAddress from './join-team/WrongEmailAddress';
import ContactAdminToUpgrade from './join-team/ContactAdminToUpgrade';
import SignInAndJoinTeamWithInviteEmailView from './SignInAndJoinTeamWithInviteEmailView';
import WarpError from './WarpError';
import './login.css';
import { checkAppInstallation } from './utils/app_detection';
import JoinTeamWithInviteEmail from './graphql/mutations/JoinTeamWithInviteEmail';
import GetUserTeams from './graphql/queries/GetUserTeams';
import GetTeamInfoFromEmailInviteCode, {
  TeamInfoFromEmailInviteCodeData,
} from './graphql/queries/GetTeamInfoFromEmailInviteCode';
import { Team } from './JoinTeamView';

export interface JoinTeamProps {
  user: User;
  logout: () => Promise<void>;
}

const JoinTeamWithInviteEmailView = ({ user, logout }: JoinTeamProps) => {
  const { emailAuthCode } = useParams<{
    emailAuthCode: string;
  }>();

  const [joinedSuccessfully, setJoinedSuccessfully] = useState(false);
  const [
    teamInfoFromEmailInviteCodeData,
    setTeamInfoFromEmailInviteCodeData,
  ] = useState<TeamInfoFromEmailInviteCodeData>();
  const [teams, setTeams] = useState<Team[]>([]);

  const [appDetectionLoading, setAppDetectionLoading] = useState<boolean>(true);
  const [appDetected, setAppDetected] = useState<boolean>(true);

  // Mutation to join a team. Doesn't execute until `joinTeamWithInviteEmailMutation` is invoked.
  const {
    query: joinTeamWithInviteEmailQuery,
    variables: joinTeamWithInviteEmailVariables,
    parseData: joinTeamWithInviteEmailParseData,
  } = JoinTeamWithInviteEmail({ emailAuthCode });
  const [joinTeamWithInviteEmailMutation] = useMutation(
    joinTeamWithInviteEmailQuery,
    {
      variables: joinTeamWithInviteEmailVariables,
      onCompleted(data) {
        setJoinedSuccessfully(joinTeamWithInviteEmailParseData(data));
      },
      onError(err) {
        err.graphQLErrors.forEach((error) => {
          // eslint-disable-next-line no-alert
          alert(`Failed to join team. ${error.message}`);
        });
      },
    }
  );

  // Check if the invite code is valid.
  // Skip the query if the user is not even logged in.
  const {
    query: getTeamInfoFromEmailInviteCodeQuery,
    variables: getTeamInfoFromEmailInviteCodeVariables,
    parseData: getTeamInfoFromEmailInviteCodeParseData,
  } = GetTeamInfoFromEmailInviteCode({ emailAuthCode });
  const {
    loading: inviteCodeQueryLoading,
    error: inviteCodeInvalid,
  } = useQuery(getTeamInfoFromEmailInviteCodeQuery, {
    skip: !user,
    variables: getTeamInfoFromEmailInviteCodeVariables,
    onCompleted(data) {
      const parsedData = getTeamInfoFromEmailInviteCodeParseData(data);
      setTeamInfoFromEmailInviteCodeData(parsedData);
    },
  });

  // Check if application is detected
  useEffect(() => {
    checkAppInstallation().then((detected) => {
      setAppDetected(detected);
      setAppDetectionLoading(false);
    });
  }, []);

  // Check if the user is already part of any teams.
  // For now, we limit the user to a single team.
  // Skip the query if the user is not even logged in.
  const {
    query: getUserTeamsQuery,
    variables: getUserTeamsVariables,
    parseData: getUserTeamsParseData,
  } = GetUserTeams();
  const { loading: userTeamsQueryLoading } = useQuery(getUserTeamsQuery, {
    skip: !user,
    variables: getUserTeamsVariables,
    onCompleted(data) {
      setTeams(getUserTeamsParseData(data));
    },
  });

  // Show sign in page if user not logged in
  if (!user) {
    return <SignInAndJoinTeamWithInviteEmailView user={user} logout={logout} />;
  }

  if (inviteCodeQueryLoading || userTeamsQueryLoading) {
    return <WarpError error="Loading..." />;
  }

  if (
    inviteCodeInvalid ||
    !teamInfoFromEmailInviteCodeData?.name ||
    !teamInfoFromEmailInviteCodeData?.inviteeEmail
  ) {
    return <Warp404 />;
  }

  if (appDetectionLoading) {
    return <WarpError error="Loading..." />;
  }

  // If the user has managed to successfully join...
  if (joinedSuccessfully) {
    return (
      <SuccessfulJoin
        appDetected={appDetected}
        teamNameFromInviteCode={teamInfoFromEmailInviteCodeData.name}
      />
    );
  }

  // Case 1: user is already in the team
  if (teamInfoFromEmailInviteCodeData?.uid) {
    if (
      teams?.some((team) => team.uid === teamInfoFromEmailInviteCodeData.uid)
    ) {
      return (
        <AlreadyInCurrentTeam
          appDetected={appDetected}
          teamNameFromInviteCode={teamInfoFromEmailInviteCodeData.name}
        />
      );
    }
  }

  // Case 2: If the team doesn't have space for more members
  if (!teamInfoFromEmailInviteCodeData?.teamAcceptingInvites) {
    return (
      <ContactAdminToUpgrade
        inviterFromInviteCode={teamInfoFromEmailInviteCodeData.inviter}
        teamNameFromInviteCode={teamInfoFromEmailInviteCodeData.name}
      />
    );
  }

  // Case 3: Invitation is expired
  if (teamInfoFromEmailInviteCodeData?.expired) {
    return (
      <ExpiredInvite
        inviterFromInviteCode={teamInfoFromEmailInviteCodeData.inviter}
        teamNameFromInviteCode={teamInfoFromEmailInviteCodeData.name}
      />
    );
  }

  // Case 4: Authenticated user's email does not match invitee email
  if (
    teamInfoFromEmailInviteCodeData?.inviteeEmail &&
    teamInfoFromEmailInviteCodeData.inviteeEmail !== user.email
  ) {
    return (
      <WrongEmailAddress
        inviterFromInviteCode={teamInfoFromEmailInviteCodeData.inviter}
        inviteeEmailFromInviteCode={
          teamInfoFromEmailInviteCodeData.inviteeEmail
        }
        logOut={logout}
        teamNameFromInviteCode={teamInfoFromEmailInviteCodeData.name}
        userEmail={user.email}
      />
    );
  }

  // Case 5: user is already in a team
  const currTeamName = teams?.[0]?.name;
  if (currTeamName) {
    return (
      <AlreadyInOtherTeam
        appDetected={appDetected}
        existingTeamName={currTeamName}
        inviterFromInviteCode={teamInfoFromEmailInviteCodeData.inviter}
        teamNameFromInviteCode={teamInfoFromEmailInviteCodeData.name}
      />
    );
  }

  // else... allow them to join the team and open Warp
  return (
    <JoinTeam
      inviterFromInviteCode={teamInfoFromEmailInviteCodeData.inviter}
      onClickFunction={() => joinTeamWithInviteEmailMutation()}
      teamNameFromInviteCode={teamInfoFromEmailInviteCodeData.name}
      userEmail={user.email}
    />
  );
};

export { JoinTeamWithInviteEmailView };
