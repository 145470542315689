import React from 'react';
import { ReactComponent as MessageQuestionCircle } from '../assets/message_question_circle.svg';
import './request-upgrade-overlay.css';
import CheckoutButton from './CheckoutButton';

export interface RequestUpgradeOverlayModalProps {
  adminEmail: string;
  onInputChange: (text: string) => void;
  sendUpgradeRequestButtonClicked: () => void;
}

const RequestUpgradeOverlayModal = ({
  adminEmail,
  onInputChange,
  sendUpgradeRequestButtonClicked,
}: RequestUpgradeOverlayModalProps) => {
  return (
    <div className="overlay-modal">
      <div className="overlay-modal-header">
        <MessageQuestionCircle />
        Ask your Warp admin to upgrade
      </div>
      <div className="overlay-modal-body">
        <p>Admin: {adminEmail}</p>
        <p>Add a message with your request (optional)</p>
        <textarea
          maxLength={200}
          rows={3}
          className="overlay-modal-input"
          placeholder="What limits did you hit? Why do you want to upgrade?"
          onChange={(e) => {
            onInputChange(e.target.value);
          }}
        />
        <br />
        <CheckoutButton
          label="Send"
          onClickFunction={sendUpgradeRequestButtonClicked}
        />
      </div>
    </div>
  );
};

export default RequestUpgradeOverlayModal;
