import React from 'react';
import { User } from '../AuthenticatedView';
import ModalContainerButton, {
  ModalContainerButtonTreatment,
} from '../modal-container/ModalContainerButton';
import trackEvent from '../utils/trackEvent';

interface NotYouProps {
  user: User;
  logout: () => void;
}

const NotYouAndFeedback = ({ user, logout }: NotYouProps) => {
  return (
    <div>
      Not you?{' '}
      <ModalContainerButton
        content={<>Log out.</>}
        treatment={ModalContainerButtonTreatment.Inline}
        onClickFunction={logout}
      />{' '}
      Or,{' '}
      <ModalContainerButton
        content={<>report an issue</>}
        treatment={ModalContainerButtonTreatment.Inline}
        href="https://github.com/warpdotdev/Warp/issues/new/choose"
        openInNewTab
        onClickFunction={() => {
          trackEvent(user, 'Clicked on send feedback');
        }}
      />
      .
    </div>
  );
};

export default NotYouAndFeedback;
