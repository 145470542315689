import React from 'react';

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import WarpError from '../WarpError';
import WarpAnsiBlock from './WarpAnsiBlock';
import { Block, DisplaySetting } from './block';
import ShareViewHeader from './ShareViewHeader';
import Warp404 from '../404';
import GetBlockQuery from '../graphql/queries/GetBlock';
import './block.css';

const BLOCK_ID_REGEX = new RegExp('^[0-9a-zA-Z]{22}$');

function ShareView() {
  const { uid } = useParams<{ uid: string }>();
  const idMatchesBlockRegex = uid ? BLOCK_ID_REGEX.test(uid) : false;
  const { query, variables, parseData } = GetBlockQuery({ uid });
  const { loading, error, data } = useQuery(query, {
    variables,
    skip: !idMatchesBlockRegex,
  });

  React.useEffect(() => {
    // Only emit event on successful/completed render.
    if (!error && !loading) {
      window.rudderanalytics.track('Viewed Share View', { uid });
    }
  }, [error, uid, loading]);

  if (!idMatchesBlockRegex) {
    return <Warp404 />;
  }

  if (error) {
    if (error.message && error.message === 'Not found: no rows in result set') {
      return <Warp404 />;
    }
    return <WarpError error={error?.message} />;
  }
  if (loading) {
    return <WarpError error="Loading..." />;
  }

  const block: Block = parseData(data);

  const showCommand =
    block.embedDisplaySetting === DisplaySetting.COMMAND ||
    block.embedDisplaySetting === DisplaySetting.COMMAND_AND_OUTPUT;

  const showOutput =
    block.embedDisplaySetting === DisplaySetting.OUTPUT ||
    block.embedDisplaySetting === DisplaySetting.COMMAND_AND_OUTPUT;

  return (
    <div className="App-embed">
      <div className="share-view-wrapper">
        <ShareViewHeader />
        <WarpAnsiBlock
          blockRenderInfo={{
            block,
            showCommand,
            showOutput,
            isEmbed: false,
          }}
          isEmbed={false}
        />
      </div>
    </div>
  );
}

export default ShareView;
