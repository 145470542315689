import { useLocation } from 'react-router-dom';
import React from 'react';

/** Custom react hook that returns a cached version of the URL query parameters */
function useSearchQuery() {
  const { search } = useLocation();
  // Memoize the `URLSearchParams` so we don't create a new instance on every re-render. See the react-router
  // docs for more information: https://v5.reactrouter.com/web/example/query-parameters.
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default useSearchQuery;
