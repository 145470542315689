export const WARP_DOWNLOAD_URL = '/get_warp?auto_download=false';

// When checking for whether or not the app is installed, we call into a small local http server
// that's run as sub-process from the main app.
// For more details, see: https://docs.google.com/document/d/1gtXisOIS7OYahlL-cxl6woPyOo5uVazswQKLeL6jyzI/edit#heading=h.jpwfi2pfd9he
export const USER_APP_INSTALLATION_DETECTION_URL =
  'http://localhost:9277/install_detection';

// Similar to the native preference settings, this must map to the setting in warp-internal
// https://github.com/warpdotdev/warp-internal/blob/master/app/src/settings/app_installation_detection.rs#L18
export const USER_APP_INSTALLATION_DETECTION_KEY = 'UserAppInstallStatus';

export enum UserAppInstallationStatus {
  NotDetected = 'NotDetected',
  Detected = 'Detected',
}

export async function checkAppInstallation(): Promise<boolean> {
  let userAppInstallationStatus = UserAppInstallationStatus.NotDetected;
  const userAppInstallationStatusSetting = window.localStorage.getItem(
    USER_APP_INSTALLATION_DETECTION_KEY
  );

  if (userAppInstallationStatusSetting !== null) {
    // Note: we have to use two JSON.parse here because of the way the warp app's API
    // writes and reads to local storage. It would be cleaner to store the value of the status
    // as just a string, but then it would not be compatible with having warp app read and
    // write to the same value.
    const installDetectionString: String = JSON.parse(
      JSON.parse(userAppInstallationStatusSetting)
    );
    const userAppInstallationStatusFromStorage =
      UserAppInstallationStatus[
        installDetectionString as keyof typeof UserAppInstallationStatus
      ];

    if (userAppInstallationStatusFromStorage !== undefined) {
      userAppInstallationStatus = userAppInstallationStatusFromStorage;
    }
  }

  // If we haven't successfully seen a warp desktop app, try to ping it
  if (userAppInstallationStatus === UserAppInstallationStatus.NotDetected) {
    fetch(USER_APP_INSTALLATION_DETECTION_URL, {
      method: 'GET',
    })
      .then((response) => {
        if (response.status === 200) {
          window.localStorage.setItem(
            USER_APP_INSTALLATION_DETECTION_KEY,
            JSON.stringify(`"${UserAppInstallationStatus.Detected}"`)
          );
        }
        return true;
      })
      .catch((_) => {
        window.localStorage.setItem(
          USER_APP_INSTALLATION_DETECTION_KEY,
          JSON.stringify(`"${UserAppInstallationStatus.NotDetected}"`)
        );
        return false;
      });
  }

  if (userAppInstallationStatus === UserAppInstallationStatus.Detected) {
    return true;
  }

  return false;
}
