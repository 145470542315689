import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';
import { AnonymousUserType } from '../../AuthenticatedView';

interface GetOrCreateUserVariables {
  referralCode: string | undefined;
  temporaryUserFirebaseUid: string | undefined;
}

interface GetOrCreateUserResult {
  uid: string;
  isOnboarded: boolean;
  anonymousUserInfo: {
    anonymousUserType: AnonymousUserType;
    linkedAt: string;
  };
  workspaces: [
    {
      joinableTeams: {
        teamUid: string;
        numMembers: number;
        name: string;
        teamAcceptingInvites: boolean;
      }[];
    }
  ];
}

export default (variables: GetOrCreateUserVariables) => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      mutation GetOrCreateUser(
        $input: GetOrCreateUserInput!
        $requestContext: RequestContext!
      ) {
        getOrCreateUser(requestContext: $requestContext, input: $input) {
          ... on GetOrCreateUserOutput {
            uid
            isOnboarded
            anonymousUserInfo {
              anonymousUserType
              linkedAt
            }
            workspaces {
              joinableTeams {
                teamUid
                numMembers
                name
                teamAcceptingInvites
              }
            }
          }
          ... on UserFacingError {
            error {
              message
            }
          }
        }
      }
    `;

    const vars = {
      input: variables,
      requestContext: getRequestContext(),
    };

    const parseData = (data: any): GetOrCreateUserResult => {
      return data?.getOrCreateUser;
    };

    return {
      query,
      variables: vars,
      parseData,
    };
  }

  const query = gql`
    mutation GetOrCreateUser(
      $referral_code: String
      $temporary_user_firebase_uid: String
    ) {
      getOrCreateUser(
        referral_code: $referral_code
        temporary_user_firebase_uid: $temporary_user_firebase_uid
      ) {
        email
        firebase_uid
        is_onboarded
        anonymous_user_info {
          anonymousUserType
          linkedAt
        }
        joinable_teams {
          teamUid
          numMembers
          name
          teamAcceptingInvites
        }
      }
    }
  `;

  const vars = {
    referral_code: variables.referralCode,
    temporary_user_firebase_uid: variables.temporaryUserFirebaseUid,
  };

  const parseData = (data: any): GetOrCreateUserResult => {
    const result: GetOrCreateUserResult = {
      uid: data.getOrCreateUser.firebase_uid,
      isOnboarded: data.getOrCreateUser.is_onboarded,
      anonymousUserInfo: data.getOrCreateUser.anonymous_user_info,
      workspaces: [
        {
          joinableTeams: data.getOrCreateUser.joinable_teams,
        },
      ],
    };

    return result;
  };

  return {
    query,
    variables: vars,
    parseData,
  };
};
