import React from 'react';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
} from '../modal-container/ModalContainerButton';
import { User } from '../AuthenticatedView';

interface MismatchedLoginModalProps {
  user: User;
  logout: () => Promise<void>;
}

const MismatchedLoginModal = ({ user, logout }: MismatchedLoginModalProps) => {
  const displayName = user?.displayName ? user.displayName : user.email;
  return (
    <ModalContainer iconType={ModalContainerIconType.Warning}>
      <ModalContainerHeader>Account mismatch detected</ModalContainerHeader>
      <ModalContainerBody>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <div>
            {displayName ? (
              <>
                You&apos;re currently logged in as{' '}
                <span
                  style={{ fontWeight: 500, color: 'rgba(255, 255, 255, 0.9)' }}
                >
                  {displayName}
                </span>
                , and this upgrade link is intended for a different login ID.
              </>
            ) : (
              <>
                This upgrade link is intended for a different login ID than your
                current account.
              </>
            )}
          </div>
          <div>Are you sure you wish to proceed using the current account?</div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <ModalContainerButton
            accent={ModalContainerButtonAccent.Primary}
            treatment={ModalContainerButtonTreatment.FullWidth}
            content={<>Yes, upgrade this account</>}
            onClickFunction={() => {
              window.open('/upgrade');
            }}
          />
          <ModalContainerButton
            accent={ModalContainerButtonAccent.Secondary}
            treatment={ModalContainerButtonTreatment.FullWidth}
            content={<>No, switch accounts</>}
            onClickFunction={logout}
          />
        </div>
      </ModalContainerBody>
    </ModalContainer>
  );
};

export default MismatchedLoginModal;
