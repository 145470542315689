import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';
import {
  StripeSubscriptionInterval,
  StripeSubscriptionPlan,
} from '../../upgrade-view/UpgradeEnums';

interface StripeCheckoutSessionVariables {
  teamUid: string | undefined;
  interval: StripeSubscriptionInterval;
  plan: StripeSubscriptionPlan;
}

export default () => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      mutation stripeCheckoutSessionWithIntervalAndPlan(
        $input: StripeCheckoutSessionInput!
        $requestContext: RequestContext!
      ) {
        stripeCheckoutSession(input: $input, requestContext: $requestContext) {
          ... on StripeCheckoutSessionOutput {
            url
          }
          ... on UserFacingError {
            error {
              message
            }
          }
        }
      }
    `;

    return {
      query,
      createVariables: (variables: StripeCheckoutSessionVariables) => ({
        input: variables,
        requestContext: getRequestContext(),
      }),
      parseData: (data: any) => data.stripeCheckoutSession?.url,
    };
  }

  const query = gql`
    mutation stripeCheckoutSessionWithIntervalAndPlan(
      $input: StripeCheckoutSessionWithIntervalAndPlanInput!
    ) {
      stripeCheckoutSessionWithIntervalAndPlan(input: $input) {
        __typename
        ... on StripeCheckoutSessionResult {
          url
        }
        ... on UserFacingError {
          error {
            message
          }
        }
      }
    }
  `;

  return {
    query,
    createVariables: (variables: StripeCheckoutSessionVariables) => ({
      input: variables,
    }),
    parseData: (data: any) =>
      data.stripeCheckoutSessionWithIntervalAndPlan?.url,
  };
};
