import React from 'react';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
  ModalContainerButtonType,
} from '../modal-container/ModalContainerButton';
import { getDefaultScheme } from '../utils/env';
import { WARP_DOWNLOAD_URL } from '../utils/app_detection';

const openIntent = () => {
  window.location.replace(`${getDefaultScheme()}://team/desktop_redirect`);
};

const installIntent = () => {
  window.location.replace(WARP_DOWNLOAD_URL);
};

const renderText = (appDetected: boolean) => {
  if (appDetected) {
    return <>Open Warp</>;
  }

  return <>Download Warp</>;
};

const OpenWarpButton = ({ appDetected }: { appDetected: boolean }) => {
  return (
    <ModalContainerButton
      treatment={ModalContainerButtonTreatment.FullWidth}
      buttonType={ModalContainerButtonType.Button}
      accent={ModalContainerButtonAccent.Primary}
      content={renderText(appDetected)}
      onClickFunction={() => {
        appDetected ? openIntent() : installIntent();
      }}
    />
  );
};

export default OpenWarpButton;
