import React from 'react';
import './upgrade.css';
import { PRO_PLAN_AI_REQUEST_LIMIT } from '../const';

const ProPlanDescription = () => {
  const numRequestsLabel =
    PRO_PLAN_AI_REQUEST_LIMIT === Infinity
      ? 'Unlimited'
      : PRO_PLAN_AI_REQUEST_LIMIT;

  return (
    <div className="upgrade-plan-description">
      For professional engineers to leverage advanced AI
      <ul>
        <li>{numRequestsLabel} Warp AI requests per user per month</li>
        <li>Private email support</li>
      </ul>
    </div>
  );
};

export default ProPlanDescription;
