import React from 'react';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import './upgrade.css';

export interface UpgradeLoadingViewProps {
  icon: ModalContainerIconType;
  headerText: string;
  bodyContent: JSX.Element;
}

const UpgradeResultView = ({
  icon,
  headerText,
  bodyContent,
}: UpgradeLoadingViewProps) => {
  return (
    <ModalContainer iconType={icon}>
      <ModalContainerHeader>{headerText}</ModalContainerHeader>
      <ModalContainerBody>{bodyContent}</ModalContainerBody>
    </ModalContainer>
  );
};

export default UpgradeResultView;
