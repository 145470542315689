import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';

interface TransferTeamOwnershipInput {
  newOwnerEmail: string;
}

export default (variables: TransferTeamOwnershipInput) => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      mutation TransferTeamOwnership(
        $requestContext: RequestContext!
        $input: TransferTeamOwnershipInput!
      ) {
        transferTeamOwnership(requestContext: $requestContext, input: $input) {
          ... on TransferTeamOwnershipOutput {
            success
          }
        }
      }
    `;

    const vars = {
      requestContext: getRequestContext(),
      input: variables,
    };

    const parseData = (data: any) => {
      return data?.transferTeamOwnership?.success;
    };

    return { query, variables: vars, parseData };
  }

  const query = gql`
    mutation TransferTeamOwnership($newOwnerEmail: String!) {
      transferTeamOwnership(newOwnerEmail: $newOwnerEmail)
    }
  `;

  const parseData = (data: any) => {
    return data?.transferTeamOwnership;
  };

  return { query, variables, parseData };
};
