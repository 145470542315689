import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';

interface SendVerificationEmailVariables {
  inviteCode: string;
  email: string;
}

export default (variables: SendVerificationEmailVariables) => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      mutation SendVerificationEmail(
        $input: SendVerificationEmailWithCodeInput!
        $requestContext: RequestContext!
      ) {
        sendVerificationEmailWithCode(
          input: $input
          requestContext: $requestContext
        ) {
          ... on SendVerificationEmailWithCodeOutput {
            success
          }
        }
      }
    `;

    const vars = {
      input: {
        teamInviteCode: variables.inviteCode,
        emailToVerifyWith: variables.email,
      },
      requestContext: getRequestContext(),
    };

    const parseData = (data: any) => {
      return data?.sendVerificationEmailWithCode?.success;
    };

    return { query, variables: vars, parseData };
  }

  const query = gql`
    mutation SendVerificationEmail($inviteCode: String!, $email: String!) {
      sendVerificationEmailWithCode(
        teamInviteCode: $inviteCode
        emailToVerifyWith: $email
      )
    }
  `;

  const vars = {
    inviteCode: variables.inviteCode,
    email: variables.email,
  };

  const parseData = (data: any) => {
    return data?.sendVerificationEmailWithCode;
  };

  return { query, variables: vars, parseData };
};
