import { gql } from '@apollo/client';

export default gql`
  query getOrganization($input: GetOrganizationInput!) {
    getOrganization(input: $input) {
      organizationId
      ssoEnabled
    }
  }
`;
