import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';

interface GetBlockVariables {
  uid: string | undefined;
}

export default (variables: GetBlockVariables) => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      query GetBlock($input: GetBlockInput!, $requestContext: RequestContext!) {
        block(input: $input, requestContext: $requestContext) {
          ... on GetBlockOutput {
            block {
              title
              stylizedPrompt
              stylizedCommand
              stylizedPromptAndCommand
              stylizedOutput
              command
              output
              showPrompt
              embedDisplaySetting
            }
          }
        }
      }
    `;

    const vars = {
      input: {
        uid: variables.uid,
      },
      requestContext: getRequestContext(),
    };

    return {
      query,
      variables: vars,
      parseData: (data: any) => data.block.block,
    };
  }

  const query = gql`
    query GetBlock($id: String!) {
      getBlock(id: $id) {
        title
        stylizedPrompt
        stylizedCommand
        stylizedPromptAndCommand
        stylizedOutput
        command
        output
        showPrompt
        embedDisplaySetting
      }
    }
  `;

  const vars = {
    id: variables.uid,
  };

  return {
    query,
    variables: vars,
    parseData: (data: any) => data.getBlock,
  };
};
