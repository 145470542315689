import React from 'react';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
} from '../modal-container/ModalContainerButton';

interface SsoAuthFormProps {
  email: string;
  setEmail: (email: string) => void;
  authWithSso: () => Promise<void>;
  onBackClicked: () => void;
}

const SsoAuthForm = ({
  email,
  setEmail,
  authWithSso,
  onBackClicked,
}: SsoAuthFormProps) => {
  return (
    <div style={{ gap: '16px' }}>
      <form
        className="auth-form-email-form"
        onSubmit={(e) => {
          e.preventDefault();
          authWithSso();
        }}
      >
        <div className="font-weight-600 text-white">Email</div>
        <input
          className="modal-container-input"
          type="text"
          placeholder="Your email address"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <ModalContainerButton
          content={<>Continue</>}
          treatment={ModalContainerButtonTreatment.FullWidth}
          accent={ModalContainerButtonAccent.Primary}
        />
        <ModalContainerButton
          content={<>Back</>}
          treatment={ModalContainerButtonTreatment.FullWidth}
          accent={ModalContainerButtonAccent.Transparent}
          onClickFunction={onBackClicked}
        />
      </form>
    </div>
  );
};

export default SsoAuthForm;
