import React from 'react';

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { Block, DisplaySetting } from './block';
import WarpError from '../WarpError';
import Warp404 from '../404';
import WarpAnsiBlock from './WarpAnsiBlock';
import GetBlockQuery from '../graphql/queries/GetBlock';
import './block.css';

function EmbedView() {
  const { uid } = useParams<{ uid: string }>();
  const { query, variables, parseData } = GetBlockQuery({ uid });
  const { loading, error, data } = useQuery(query, { variables });

  React.useEffect(() => {
    // Only emit event on successful/completed render.
    if (!error && !loading) {
      window.rudderanalytics.track('Viewed Embed View', { uid });
    }
  }, [error, uid, loading]);

  if (error) {
    if (error.message && error.message === 'Not found: no rows in result set') {
      return <Warp404 />;
    }
    return <WarpError error={error?.message} />;
  }
  if (loading) {
    return <WarpError error="Loading..." />;
  }

  const block: Block = parseData(data);

  const showCommand =
    block.embedDisplaySetting === DisplaySetting.COMMAND ||
    block.embedDisplaySetting === DisplaySetting.COMMAND_AND_OUTPUT;

  const showOutput =
    block.embedDisplaySetting === DisplaySetting.OUTPUT ||
    block.embedDisplaySetting === DisplaySetting.COMMAND_AND_OUTPUT;

  return (
    <div className="App-embed">
      <div className="embed-view-wrapper">
        <WarpAnsiBlock
          blockRenderInfo={{
            block,
            showCommand,
            showOutput,
            isEmbed: true,
          }}
          isEmbed
        />
      </div>
    </div>
  );
}

export default EmbedView;
