import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Navigate, useParams } from 'react-router-dom';
import { User } from './AuthenticatedView';
import Warp404 from './404';
import AuthView from './auth/AuthView';
import GetTeamInfoFromInviteCode, {
  TeamInfoFromInviteCodeData,
} from './graphql/queries/GetTeamInfoFromInviteCode';

interface TeamLoginProps {
  user: User | undefined;
  logout: () => Promise<void>;
}

/**
 * `SignInAndJoinTeamView` allows users to view team invite and sign up for Warp.
 * On success, they will be redirected to a logged in page with a download button.
 */
const SignInAndJoinTeamView = ({ user, logout }: TeamLoginProps) => {
  const { inviteCode } = useParams<{
    inviteCode: string;
  }>();
  const [
    teamInfoFromInviteCodeData,
    setTeamInfoFromInviteCodeData,
  ] = useState<TeamInfoFromInviteCodeData>();

  // Check if the team code is valid.
  const {
    query: getTeamInfoFromInviteCodeQuery,
    variables: getTeamInfoFromInviteCodeVariables,
    parseData: getTeamInfoFromInviteCodeParseData,
  } = GetTeamInfoFromInviteCode({ code: inviteCode });
  const {
    loading: inviteCodeQueryLoading,
    error: inviteCodeInvalid,
  } = useQuery(getTeamInfoFromInviteCodeQuery, {
    variables: getTeamInfoFromInviteCodeVariables,
    onCompleted(data) {
      setTeamInfoFromInviteCodeData(getTeamInfoFromInviteCodeParseData(data));
    },
  });

  if (
    !inviteCodeQueryLoading &&
    (inviteCodeInvalid ||
      !teamInfoFromInviteCodeData?.name ||
      !teamInfoFromInviteCodeData?.isInviteLinkEnabled)
  ) {
    if (user?.isTelemetryEnabled) {
      window.rudderanalytics.track('Visited Team Signup with Invalid Code', {
        inviteCode,
      });
    }
    return <Warp404 />;
  }

  if (user) {
    return <Navigate to={`/team/${inviteCode}`} replace />;
  }

  window.rudderanalytics.track('Visited Team Signup of Team', {
    teamNameFromInviteCode: teamInfoFromInviteCodeData?.name,
  });

  return (
    <AuthView
      headerContent={
        <>
          You&apos;ve been invited to join the{' '}
          <span className="font-weight-600">
            {teamInfoFromInviteCodeData?.name}
          </span>{' '}
          team on Warp.
        </>
      }
      showLoadingState={inviteCodeQueryLoading}
      buttonTrackingObject={{
        segmentMessage: 'Click Sign Up Button on Referred Signup View',
        googleAnalyticsMessage: 'Sign Up on Referred Sign Up View',
        googleAnalyticsCategory: 'Sign Up',
      }}
      user={user}
      logout={logout}
    />
  );
};

export default SignInAndJoinTeamView;
