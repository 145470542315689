import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';

export enum AllowedObjectTypes {
  Notebook = 'notebook',
  Workflow = 'workflow',
  EnvironmentVariables = 'env-vars',
}

interface GetCloudObjectVariables {
  uid: string;
}

interface GetCloudObjectOutput {
  name: string | null;
  typename: string;
  isTrashed: number;
}

function getObjectName(object: any): string | null {
  const { __typename: typename, titleName, data: jsonData, format } = object;

  if (typename === 'NewNotebook' || typename === 'Notebook') {
    return titleName;
  }

  if (typename === 'Workflow') {
    return JSON.parse(jsonData).name;
  }

  if (typename === 'GenericStringObject') {
    if (format === 'JsonEnvVarCollection') {
      return 'environment variables';
    }
  }
  return null;
}

export default (variables: GetCloudObjectVariables) => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      query GetCloudObject(
        $input: CloudObjectInput!
        $requestContext: RequestContext!
      ) {
        cloudObject(input: $input, requestContext: $requestContext) {
          ... on CloudObjectOutput {
            object {
              ... on Notebook {
                title
                metadata {
                  trashedTs
                }
              }
              ... on Workflow {
                data
                metadata {
                  trashedTs
                }
              }
              ... on GenericStringObject {
                format
                metadata {
                  trashedTs
                }
              }
            }
          }
        }
      }
    `;

    const vars = {
      input: {
        uid: variables.uid,
      },
      requestContext: getRequestContext(),
    };

    const parseData = (data: any): GetCloudObjectOutput => {
      return {
        name: getObjectName(data?.cloudObject?.object),
        // eslint-disable-next-line no-underscore-dangle
        typename: data?.cloudObject?.object?.__typename,
        isTrashed: data?.cloudObject?.object?.metadata?.trashedTs,
      };
    };

    return { query, variables: vars, parseData };
  }

  const query = gql`
    query GetCloudObject($uid: ID!) {
      getCloudObject(input: { objectId: $uid }) {
        object {
          ... on NewNotebook {
            titleName
            metadata {
              trashedTs
            }
          }
          ... on Workflow {
            data
            metadata {
              trashedTs
            }
          }
          ... on GenericStringObject {
            format
            metadata {
              trashedTs
            }
          }
        }
      }
    }
  `;

  const vars = {
    uid: variables.uid,
  };

  const parseData = (data: any): GetCloudObjectOutput => {
    return {
      name: getObjectName(data?.getCloudObject?.object),
      // eslint-disable-next-line no-underscore-dangle
      typename: data?.getCloudObject?.object?.__typename,
      isTrashed: data?.getCloudObject?.object?.metadata?.trashedTs,
    };
  };

  return { query, variables: vars, parseData };
};
