import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';
import { FEATURE_FLAGS } from '../../utils/env';

export interface JoinTeamWithAuthCodeVariables {
  inviteCode: string;
  authCode: string;
}

export default (variables: JoinTeamWithAuthCodeVariables) => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      mutation JoinTeamWithAuthCode(
        $input: JoinTeamWithAuthCodeInput!
        $requestContext: RequestContext!
      ) {
        joinTeamWithAuthCode(input: $input, requestContext: $requestContext) {
          ... on JoinTeamWithAuthCodeOutput {
            success
          }
        }
      }
    `;

    const vars = {
      input: {
        teamInviteCode: variables.inviteCode,
        authCode: variables.authCode,
      },
      requestContext: getRequestContext(),
    };

    const parseData = (data: any) => {
      return data?.joinTeamWithAuthCode?.success;
    };

    return { query, variables: vars, parseData };
  }

  const query = gql`
    mutation SubmitVerificationCode($inviteCode: String!, $authCode: String!) {
      joinTeamWithAuthCode(teamInviteCode: $inviteCode, authCode: $authCode)
    }
  `;

  const vars = {
    inviteCode: variables.inviteCode,
    authCode: variables.authCode,
  };

  const parseData = (data: any) => {
    return data?.joinTeamWithAuthCode;
  };

  return { query, variables: vars, parseData };
};
