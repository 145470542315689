import React, { FormEvent, useState } from 'react';
import { useMutation } from '@apollo/client';
import ModalContainer, {
  ModalContainerIconType,
} from './modal-container/ModalContainer';
import ModalContainerBody from './modal-container/ModalContainerBody';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
} from './modal-container/ModalContainerButton';
import ModalContainerHeader from './modal-container/ModalContainerHeader';
import SendRequestObjectAccessEmail from './graphql/mutations/SendRequestObjectAccessEmail';

type RequestAccessModalParams = {
  objectUID: string;
};

function RequestAccessModal({
  objectUID,
}: RequestAccessModalParams): JSX.Element {
  const [accessEmailSent, setAccessEmailSent] = useState(false);

  const { query, variables, parseData } = SendRequestObjectAccessEmail({
    uid: objectUID,
  });
  const [sendObjectAccessRequest] = useMutation(query, {
    variables,
    onCompleted(data) {
      setAccessEmailSent(parseData(data));
    },
    onError(err) {
      err.graphQLErrors.forEach((error) => {
        // eslint-disable-next-line no-alert
        alert(`Failed to send object access request. ${error.message}`);
      });
    },
  });

  const onRequestAccess = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendObjectAccessRequest();
  };

  return (
    <>
      {accessEmailSent ? (
        <ModalContainer iconType={ModalContainerIconType.Check}>
          <ModalContainerHeader>
            Your request has been sent.
          </ModalContainerHeader>
        </ModalContainer>
      ) : (
        <ModalContainer iconType={ModalContainerIconType.Logo}>
          <ModalContainerHeader>
            You do not have access to this link.
          </ModalContainerHeader>
          <ModalContainerBody>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
              <form onSubmit={onRequestAccess}>
                <ModalContainerButton
                  content={<>Request Access</>}
                  treatment={ModalContainerButtonTreatment.FullWidth}
                  accent={ModalContainerButtonAccent.Primary}
                />
              </form>
            </div>
          </ModalContainerBody>
        </ModalContainer>
      )}
    </>
  );
}

export default RequestAccessModal;
