import React from 'react';

import { useParams } from 'react-router-dom';
import { ReactComponent as WarpLogoWithText } from '../assets/warp_logo_with_text.svg';

function ShareViewHeader() {
  const { id } = useParams<{ id: string }>();
  const getWarp = () => {
    window.rudderanalytics.track('Share View: Get Warp', { id });
    window.location.href = 'https://app.warp.dev/get_warp';
  };

  return (
    <div className="top-banner-share-view">
      <a href="https://www.warp.dev">
        <WarpLogoWithText title="Warp Logo" />
      </a>
      <div className="banner-get-warp-button">
        <button
          type="button"
          className="banner-warp-download-button"
          onClick={() => {
            getWarp();
          }}
        >
          Get Warp
        </button>
      </div>
    </div>
  );
}

export default ShareViewHeader;
