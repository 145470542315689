/**
 * APIs for interacting with the Warp on Web client.
 */

export enum WarpEventKind {
  // The user logged out of Warp on Web.
  LoggedOut = 'LOGGED_OUT',
  // A shared session was joined as a viewer.
  SessionJoined = 'SESSION_JOINED',
  // An error-level log was emitted.
  ErrorLogged = 'ERROR_LOGGED',
  // User has chosen to open on the native app.
  OpenOnNative = 'OPEN_ON_NATIVE',
}

export interface WarpEvent {
  kind: WarpEventKind;
}

export interface LoggedOutEvent extends WarpEvent {
  kind: WarpEventKind.LoggedOut;
}

export interface SessionJoinedEvent extends WarpEvent {
  kind: WarpEventKind.SessionJoined;
}

export interface ErrorLoggedEvent extends WarpEvent {
  kind: WarpEventKind.ErrorLogged;
  error: string;
}

export interface OpenOnNativeEvent extends WarpEvent {
  kind: WarpEventKind.OpenOnNative;
  url: string;
}

/**
 * An event bus for receiving notifications from the Warp on Web client.
 */
export class WarpEventBus {
  private listeners: Set<(event: WarpEvent) => void> = new Set();

  constructor() {
    window.warpEmitEvent = this.emit.bind(this);
  }

  /**
   * Add a new listener for Warp events.
   * @param listener the listener function
   * @returns a function to remove the registered listener
   */
  addListener(listener: (event: WarpEvent) => void): () => void {
    this.listeners.add(listener);
    return () => {
      this.listeners.delete(listener);
    };
  }

  /**
   * Emit an event to all subscribed listeners.
   *
   * This is [soft-private](https://www.typescriptlang.org/docs/handbook/2/classes.html#private),
   * so that the rest of the app cannot emit events, only the Warp on Web client.
   */
  private emit(event: WarpEvent) {
    this.listeners.forEach((listener) => {
      listener(event);
    });
  }
}

/**
 * The single, global Warp event bus.
 */
export const warpEventBus = new WarpEventBus();

declare global {
  interface Window {
    /**
     * Hook for the Warp client to emit events.
     */
    warpEmitEvent: (event: WarpEvent) => void;
  }
}
