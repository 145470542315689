import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useApolloClient } from '@apollo/client';
import Warp404 from './404';
import { User } from './AuthenticatedView';
import AlreadyInCurrentTeam from './join-team/AlreadyInCurrentTeam';
import AlreadyInOtherTeam from './join-team/AlreadyInOtherTeam';
import ExpiredInvite from './join-team/ExpiredInvite';
import { Inviter } from './join-team/InviterHeading';
import JoinTeam from './join-team/JoinTeam';
import SuccessfulJoin from './join-team/SuccessfulJoin';
import WrongEmailAddress from './join-team/WrongEmailAddress';
import ContactAdminToUpgrade from './join-team/ContactAdminToUpgrade';
import SignInAndJoinTeamWithInviteEmailView from './SignInAndJoinTeamWithInviteEmailView';
import WarpError from './WarpError';
import './login.css';
import { checkAppInstallation } from './utils/app_detection';
import JoinTeamWithInviteEmail from './graphql/mutations/JoinTeamWithInviteEmail';
import GetUserTeams from './graphql/queries/GetUserTeams';
import GetTeamInfoFromEmailInviteCode from './graphql/queries/GetTeamInfoFromEmailInviteCode';

export interface JoinTeamProps {
  user: User;
  logout: () => Promise<void>;
}

export interface Team {
  uid: String;
  name: String;
}

const JoinTeamWithInviteEmailView = ({ user, logout }: JoinTeamProps) => {
  const { emailAuthCode } = useParams<{
    emailAuthCode: string;
  }>();

  const client = useApolloClient();

  const [joinedSuccessfully, setJoinedSuccessfully] = useState(false);
  const [
    teamNameFromInviteCode,
    setTeamNameFromInviteCode,
  ] = useState<string>();
  const [teamUIDFromInviteCode, setTeamUIDFromInviteCode] = useState<string>();
  const [inviterFromInviteCode, setInviterFromInviteCode] = useState<Inviter>();
  const [
    inviteeEmailFromInviteCode,
    setInviteeEmailFromInviteCode,
  ] = useState<string>();
  const [inviteExpired, setInviteExpired] = useState(Boolean);
  const [teamAcceptingInvites, setTeamAcceptingInvites] = useState(Boolean);

  const [userTeamNames, setUserTeamNames] = useState<string[]>();
  const [userTeamUIDs, setUserTeamUIDs] = useState<string[]>();
  const [appDetectionLoading, setAppDetectionLoading] = useState<boolean>(true);
  const [appDetected, setAppDetected] = useState<boolean>(true);

  // Mutation to join a team. Doesn't execute until `joinTeamWithInviteEmailMutation` is invoked.
  const [joinTeamWithInviteEmailMutation] = useMutation(
    JoinTeamWithInviteEmail,
    {
      client,
      variables: { input: { emailAuthCode } },
      onCompleted(data) {
        if (data?.joinTeamWithInviteEmail.success) {
          setJoinedSuccessfully(true);
        }
      },
      onError(err) {
        err.graphQLErrors.forEach((error) => {
          // eslint-disable-next-line no-alert
          alert(`Failed to join team. ${error.message}`);
        });
      },
    }
  );

  // Check if the invite code is valid.
  // Skip the query if the user is not even logged in.
  const {
    loading: inviteCodeQueryLoading,
    error: inviteCodeInvalid,
  } = useQuery(GetTeamInfoFromEmailInviteCode, {
    skip: !user,
    variables: { input: { emailAuthCode } },
    onCompleted(data) {
      if (data?.getTeamInfoFromEmailInviteCode) {
        setTeamNameFromInviteCode(data.getTeamInfoFromEmailInviteCode.name);
        setTeamUIDFromInviteCode(data.getTeamInfoFromEmailInviteCode.uid);
        setInviterFromInviteCode({
          name: data.getTeamInfoFromEmailInviteCode.inviterDisplayName,
          email: data.getTeamInfoFromEmailInviteCode.inviterEmail,
          photoUrl: data.getTeamInfoFromEmailInviteCode.inviterPhotoUrl,
        });
        setInviteeEmailFromInviteCode(
          data.getTeamInfoFromEmailInviteCode.emailInvitation.inviteeEmail
        );
        setInviteExpired(
          data.getTeamInfoFromEmailInviteCode.emailInvitation.expired
        );
        setTeamAcceptingInvites(
          data.getTeamInfoFromEmailInviteCode.teamAcceptingInvites
        );
      }
    },
  });

  // Check if application is detected
  useEffect(() => {
    checkAppInstallation().then((detected) => {
      setAppDetected(detected);
      setAppDetectionLoading(false);
    });
  }, []);

  // Check if the user is already part of any teams.
  // For now, we limit the user to a single team.
  // Skip the query if the user is not even logged in.
  const { loading: userTeamsQueryLoading } = useQuery(GetUserTeams, {
    skip: !user,
    onCompleted(data) {
      setUserTeamNames(data?.user?.teams.map((team: Team) => team.name));
      setUserTeamUIDs(data?.user?.teams.map((team: Team) => team.uid));
    },
  });

  // Show sign in page if user not logged in
  if (!user) {
    return <SignInAndJoinTeamWithInviteEmailView user={user} logout={logout} />;
  }

  if (inviteCodeQueryLoading || userTeamsQueryLoading) {
    return <WarpError error="Loading..." />;
  }

  function currentTeamName(): string | undefined {
    return userTeamNames?.[0];
  }

  function isAlreadyInCurrentTeam(teamUID: string): boolean {
    return userTeamUIDs?.includes(teamUID) ?? false;
  }

  if (
    inviteCodeInvalid ||
    !teamNameFromInviteCode ||
    !inviteeEmailFromInviteCode
  ) {
    return <Warp404 />;
  }

  if (appDetectionLoading) {
    return <WarpError error="Loading..." />;
  }

  // If the user has managed to successfully join...
  if (joinedSuccessfully) {
    return (
      <SuccessfulJoin
        appDetected={appDetected}
        teamNameFromInviteCode={teamNameFromInviteCode}
      />
    );
  }

  // Case 1: user is already in the team
  if (teamUIDFromInviteCode) {
    if (isAlreadyInCurrentTeam(teamUIDFromInviteCode)) {
      return (
        <AlreadyInCurrentTeam
          appDetected={appDetected}
          teamNameFromInviteCode={teamNameFromInviteCode}
        />
      );
    }
  }

  // Case 2: If the team doesn't have space for more members
  if (!teamAcceptingInvites) {
    return (
      <ContactAdminToUpgrade
        inviterFromInviteCode={inviterFromInviteCode}
        teamNameFromInviteCode={teamNameFromInviteCode}
      />
    );
  }

  // Case 3: Invitation is expired
  if (inviteExpired) {
    return (
      <ExpiredInvite
        inviterFromInviteCode={inviterFromInviteCode}
        teamNameFromInviteCode={teamNameFromInviteCode}
      />
    );
  }

  // Case 4: Authenticated user's email does not match invitee email
  if (inviteeEmailFromInviteCode && inviteeEmailFromInviteCode !== user.email) {
    return (
      <WrongEmailAddress
        inviterFromInviteCode={inviterFromInviteCode}
        inviteeEmailFromInviteCode={inviteeEmailFromInviteCode}
        logOut={logout}
        teamNameFromInviteCode={teamNameFromInviteCode}
        userEmail={user.email}
      />
    );
  }

  // Case 5: user is already in a team
  const currTeamName = currentTeamName();
  if (currTeamName) {
    return (
      <AlreadyInOtherTeam
        appDetected={appDetected}
        existingTeamName={currTeamName}
        inviterFromInviteCode={inviterFromInviteCode}
        teamNameFromInviteCode={teamNameFromInviteCode}
      />
    );
  }

  // else... allow them to join the team and open Warp
  return (
    <JoinTeam
      inviterFromInviteCode={inviterFromInviteCode}
      onClickFunction={() => joinTeamWithInviteEmailMutation()}
      teamNameFromInviteCode={teamNameFromInviteCode}
      userEmail={user.email}
    />
  );
};

export { JoinTeamWithInviteEmailView };
