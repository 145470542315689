import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';

export default () => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      mutation DeleteUser($requestContext: RequestContext!) {
        deleteUser(requestContext: $requestContext) {
          ... on DeleteUserOutput {
            success
          }
        }
      }
    `;

    const variables = {
      requestContext: getRequestContext(),
    };

    return { query, variables };
  }

  const query = gql`
    mutation DeleteUser {
      deleteUser {
        success
      }
    }
  `;

  return { query, variables: {} };
};
