import { gql } from '@apollo/client';
import getRequestContext from '../get-request-context';
import { FEATURE_FLAGS } from '../../utils/env';

interface GetReferrerVariables {
  referralCode: string | undefined;
}

export default (variables: GetReferrerVariables) => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      query referrer($input: ReferrerInput!, $requestContext: RequestContext!) {
        referrer(input: $input, requestContext: $requestContext) {
          ... on ReferrerOutput {
            referrer
          }
        }
      }
    `;

    const vars = {
      input: {
        referralCode: variables.referralCode,
      },
      requestContext: getRequestContext(),
    };

    const parseData = (data: any) => {
      return data?.referrer?.referrer;
    };

    return { query, variables: vars, parseData };
  }

  const query = gql`
    query GetReferrer($referral_code: String!) {
      referrer(referral_code: $referral_code)
    }
  `;

  const vars = {
    referral_code: variables.referralCode,
  };

  const parseData = (data: any) => {
    return data?.referrer;
  };

  return { query, variables: vars, parseData };
};
