/**
 * Helpers for checking if the Warp on Web client is supported in this browser.
 */

function isWebGL2Available(): boolean {
  const canvas = document.createElement('canvas');
  try {
    const context = canvas.getContext('webgl2');
    return context != null;
  } catch {
    return false;
  }
}

function checkGraphicsSupport(): string | null {
  // First, check if WebGL2 is supported at all. If not, the user's browser is likely too old.
  if (!window.WebGL2RenderingContext) {
    return 'WebGL2 is not supported. Please update it and/or your graphics drivers.';
  }

  if (!isWebGL2Available()) {
    return 'WebGL2 is not enabled. Check if hardware acceleration is turned on.';
  }

  return null;
}

let cachedStatus: WarpOnWebStatus;

/**
 * Check if the user's browser supports Warp on Web
 */
export function checkWoWStatus(): WarpOnWebStatus {
  if (!cachedStatus) {
    let status: WarpOnWebStatus = { supported: true };

    const graphicsError = checkGraphicsSupport();
    if (graphicsError) {
      status = {
        supported: false,
        error: `Your browser does not support Warp on Web - ${graphicsError}`,
        supportLink: 'https://get.webgl.org',
      };
    }

    cachedStatus = status;
  }

  return cachedStatus;
}

export type WarpOnWebSupported = { supported: true };
export type WarpOnWebUnsupported = {
  supported: false;
  error: string;
  supportLink?: string;
};

export type WarpOnWebStatus = WarpOnWebSupported | WarpOnWebUnsupported;
