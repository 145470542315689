import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';

export default () => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      mutation CreateAnonymousUser(
        $input: CreateAnonymousUserInput!
        $requestContext: RequestContext!
      ) {
        createAnonymousUser(input: $input, requestContext: $requestContext) {
          ... on CreateAnonymousUserOutput {
            idToken
          }
        }
      }
    `;

    const variables = {
      input: {
        anonymousUserType: 'WEB_CLIENT_ANONYMOUS_USER',
        expirationType: 'NO_EXPIRATION',
      },
      requestContext: getRequestContext(),
    };

    const parseData = (data: any) => {
      return data?.createAnonymousUser?.idToken;
    };

    return { query, variables, parseData };
  }

  const query = gql`
    mutation CreateAnonymousUser($input: CreateAnonymousUserInput!) {
      createAnonymousUser(input: $input) {
        ... on CreateAnonymousUserOutput {
          id_token
        }
      }
    }
  `;

  const variables = {
    input: {
      anonymousUserType: 'WEB_CLIENT_ANONYMOUS_USER',
      expirationType: 'NO_EXPIRATION',
    },
  };

  const parseData = (data: any) => {
    return data?.createAnonymousUser?.id_token;
  };

  return { query, variables, parseData };
};
