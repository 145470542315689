import { gql } from '@apollo/client';

export default gql`
  query GetTeamInfoFromEmailInviteCode(
    $input: GetTeamInfoFromEmailInviteCodeInput!
  ) {
    getTeamInfoFromEmailInviteCode(input: $input) {
      name
      uid
      emailInvitation {
        inviteeEmail
        expired
      }
      inviterEmail
      inviterDisplayName
      inviterPhotoUrl
      teamAcceptingInvites
    }
  }
`;
