import { gql } from '@apollo/client';

export default gql`
  mutation sendUpgradeRequest($input: SendUpgradeRequestInput!) {
    sendUpgradeRequest(input: $input) {
      __typename
      ... on SendUpgradeRequestSuccess {
        success
      }
      ... on UserFacingError {
        error {
          message
        }
      }
    }
  }
`;
