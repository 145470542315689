import { gql } from '@apollo/client';

export default gql`
  mutation GetOrCreateUser($referral_code: String) {
    getOrCreateUser(referral_code: $referral_code) {
      email
      firebase_uid
      is_user_new
      is_onboarded
      anonymous_user_info {
        anonymousUserType
        linkedAt
      }
      joinable_teams {
        teamUid
        numMembers
        name
        teamAcceptingInvites
      }
    }
  }
`;
