import React from 'react';
import {
  PRO_PLAN_MONTHLY_COST_PER_SEAT_PER_MONTH,
  PRO_PLAN_YEARLY_COST_PER_SEAT_PER_MONTH,
  PRO_PLAN_YEARLY_DISCOUNT_PERCENTAGE,
  TEAM_PLAN_MONTHLY_COST_PER_SEAT_PER_MONTH,
  TEAM_PLAN_YEARLY_COST_PER_SEAT_PER_MONTH,
  TEAM_PLAN_YEARLY_DISCOUNT_PERCENTAGE,
} from '../const';
import './upgrade.css';
import {
  StripeSubscriptionInterval,
  StripeSubscriptionPlan,
} from './UpgradeEnums';

export interface PlanCostCalculationProps {
  interval: StripeSubscriptionInterval;
  numMembers: number;
  plan: StripeSubscriptionPlan;
}

const PlanCostCalculation = ({
  interval,
  numMembers,
  plan,
}: PlanCostCalculationProps) => {
  const planMonthlyCostPerSeatPerMonth =
    plan === StripeSubscriptionPlan.PRO
      ? PRO_PLAN_MONTHLY_COST_PER_SEAT_PER_MONTH
      : TEAM_PLAN_MONTHLY_COST_PER_SEAT_PER_MONTH;
  const planYearlyCostPerSeatPerMonth =
    plan === StripeSubscriptionPlan.PRO
      ? PRO_PLAN_YEARLY_COST_PER_SEAT_PER_MONTH
      : TEAM_PLAN_YEARLY_COST_PER_SEAT_PER_MONTH;
  const yearlyDiscountPercentage =
    plan === StripeSubscriptionPlan.PRO
      ? PRO_PLAN_YEARLY_DISCOUNT_PERCENTAGE
      : TEAM_PLAN_YEARLY_DISCOUNT_PERCENTAGE;

  return (
    <div className="upgrade-body">
      <div className="upgrade-body-row">
        <p className="upgrade-body-text--left">Seats</p>
        <p className="upgrade-body-text--right">{numMembers}</p>
      </div>
      <div className="upgrade-body-row">
        <p className="upgrade-body-text--left">x</p>
        <p className="upgrade-body-text--right">
          $
          {interval === StripeSubscriptionInterval.MONTHLY
            ? planMonthlyCostPerSeatPerMonth
            : planYearlyCostPerSeatPerMonth}
        </p>
      </div>
      <hr className="upgrade-hr" />
      <div className="upgrade-body-row">
        <p className="upgrade-body-text--left">=</p>
        <div className="upgrade-body-text--badge">
          {interval === StripeSubscriptionInterval.YEARLY && (
            <div className="upgrade-discount-badge">
              Save {yearlyDiscountPercentage}%
            </div>
          )}
          $
          {interval === StripeSubscriptionInterval.MONTHLY
            ? planMonthlyCostPerSeatPerMonth * numMembers
            : planYearlyCostPerSeatPerMonth * numMembers}
          /mo
        </div>
      </div>
    </div>
  );
};

export default PlanCostCalculation;
