import React, { useState } from 'react';
import ModalContainerButton, {
  ModalContainerButtonTreatment,
} from '../modal-container/ModalContainerButton';
import './email-sent-view.css';

interface EmailSentViewProps {
  email: string;
  resendEmail: () => Promise<void>;
}

const EmailSentView = ({ email, resendEmail }: EmailSentViewProps) => {
  const [emailResent, setEmailResent] = useState(false);

  return (
    <>
      <div>
        Click the link sent to{' '}
        <span className="font-weight-600 text-white">{email}</span> to finish
        signing in.
      </div>
      <div>
        Don&apos;t see it?{' '}
        <ModalContainerButton
          content={<>Resend email</>}
          treatment={ModalContainerButtonTreatment.Inline}
          onClickFunction={async () => {
            setEmailResent(false);
            await resendEmail();
            setEmailResent(true);
          }}
        />
      </div>
      <div className={`email-resent${emailResent ? '--visible' : ''}`}>
        Email resent! Please check your inbox again.
      </div>
    </>
  );
};

export default EmailSentView;
