import React from 'react';
import { Navigate } from 'react-router-dom';

import { User } from '../AuthenticatedView';
import '../download.css';
import '../login.css';
import UserNameAndPhoto from './UserNameAndPhoto';
import DownloadDropdown from '../download/DownloadDropdown';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import ModalContainerButton, {
  ModalContainerButtonTreatment,
} from '../modal-container/ModalContainerButton';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import NotYouAndFeedback from './NotYouAndFeedback';
import trackEvent from '../utils/trackEvent';

/**
 * `LoggedInWithDownloadView` allows users to download Warp upon log in.
 * They are shown this screen if they attempted to claim a referral via
 * `ReferredSignupView`. In both these flows, we should assume the user had
 * yet to download the app from our site.
 */

interface LoggedInProps {
  user: User;
  logout: () => void;
}

const LoggedInWithDownloadView = ({ user, logout }: LoggedInProps) => {
  trackEvent(user, 'Visited Referred Logged in View');

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return (
    <ModalContainer iconType={ModalContainerIconType.Check}>
      <ModalContainerHeader>
        <UserNameAndPhoto user={user} />
      </ModalContainerHeader>
      <ModalContainerBody>
        <DownloadDropdown />
        <NotYouAndFeedback user={user} logout={logout} />
        <div>
          <ModalContainerButton
            treatment={ModalContainerButtonTreatment.Inline}
            content={<>Sign up</>}
            href="https://warp.dev/windows-terminal"
            openInNewTab
          />{' '}
          to join the Windows waitlist.
        </div>
      </ModalContainerBody>
    </ModalContainer>
  );
};

export default LoggedInWithDownloadView;
