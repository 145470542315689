export enum AuthErrorType {
  AuthedWithDifferentProvider = 1,
  Generic = 2,
  NoSsoConnection = 3,
  MustUseSso = 4,
  EmailAlreadyExists = 5,
  CredentialAlreadyInUse = 6,
}

export interface AuthTrackingObject {
  segmentMessage: string;
  googleAnalyticsMessage: string;
  googleAnalyticsCategory: string;
}

export enum AuthState {
  AuthForm,
  EmailSent,
  NeedsConfirmEmail,
  Sso,
}

export enum AuthFormProviderButtonType {
  GitHub,
  Google,
  Sso,
}

// Local storage key to hold the user's email if they've tried to login via an email link.
// This is used so if they click the login link from the same browser, we can log them in
// without asking for their email again.
export const LOCAL_STORAGE_USER_KEY = 'emailForSignIn';
