import React from 'react';
import './upgrade.css';
import './request-upgrade-overlay.css';
import UpgradeModalContainer from './UpgradeModalContainer';
import ProPlanDescription from './ProPlanDescription';
import TeamPlanDescription from './TeamPlanDescription';
import PlanCostCalculation from './PlanCostCalculation';
import CheckoutButton from './CheckoutButton';
import {
  StripeSubscriptionInterval,
  StripeSubscriptionPlan,
} from './UpgradeEnums';
import IntervalToggle from './IntervalToggle';

export interface ProAndTeamPlansViewProps {
  isAdmin: boolean;
  overlay?: React.ReactNode;
  interval: StripeSubscriptionInterval;
  numMembers: number;
  monthlyButtonFunction: () => void;
  yearlyButtonFunction: () => void;
  proPlanButtonFunction: () => void;
  teamPlanButtonFunction: () => void;
}

export default function ProAndTeamPlansView({
  isAdmin,
  overlay,
  interval,
  numMembers,
  monthlyButtonFunction,
  yearlyButtonFunction,
  proPlanButtonFunction,
  teamPlanButtonFunction,
}: ProAndTeamPlansViewProps) {
  return (
    <div className="background">
      {overlay && <div className="overlay">{overlay}</div>}
      <div className="upgrade-background">
        <IntervalToggle
          inModal={false}
          interval={interval}
          monthlyOnClickFunction={monthlyButtonFunction}
          yearlyOnClickFunction={yearlyButtonFunction}
        />
        <div className="upgrade-modal-containers-row">
          <UpgradeModalContainer header={<div className="plan-badge">Pro</div>}>
            <ProPlanDescription />
            <PlanCostCalculation
              interval={interval}
              numMembers={numMembers}
              plan={StripeSubscriptionPlan.PRO}
            />
            <CheckoutButton
              label={isAdmin ? 'Select' : 'Ask your Warp admin to upgrade'}
              onClickFunction={proPlanButtonFunction}
            />
          </UpgradeModalContainer>
          <UpgradeModalContainer
            header={<div className="plan-badge">Team</div>}
          >
            <TeamPlanDescription />
            <PlanCostCalculation
              interval={interval}
              numMembers={numMembers}
              plan={StripeSubscriptionPlan.TEAM}
            />
            <CheckoutButton
              label={isAdmin ? 'Select' : 'Ask your Warp admin to upgrade'}
              onClickFunction={teamPlanButtonFunction}
            />
          </UpgradeModalContainer>
        </div>
      </div>
    </div>
  );
}

ProAndTeamPlansView.defaultProps = {
  overlay: undefined,
};
