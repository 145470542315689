import React from 'react';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import InviterHeading, { Inviter } from './InviterHeading';

export interface ExpiredInviteProps {
  inviterFromInviteCode: Inviter | undefined;
  teamNameFromInviteCode: string | undefined;
}

const ExpiredInvite = ({
  inviterFromInviteCode,
  teamNameFromInviteCode,
}: ExpiredInviteProps) => {
  return (
    <ModalContainer iconType={ModalContainerIconType.Warning}>
      <ModalContainerHeader>
        <InviterHeading
          inviter={inviterFromInviteCode}
          teamName={teamNameFromInviteCode}
        />
      </ModalContainerHeader>
      <ModalContainerBody>
        This invitation has expired. Please contact the team administrator for
        another invitation.
      </ModalContainerBody>
    </ModalContainer>
  );
};

export default ExpiredInvite;
