import { gql } from '@apollo/client';

export default gql`
  mutation SendVerificationEmail($inviteCode: String!, $email: String!) {
    sendVerificationEmailWithCode(
      teamInviteCode: $inviteCode
      emailToVerifyWith: $email
    )
  }
`;
