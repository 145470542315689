import { gql } from '@apollo/client';

export default gql`
  query GetTeamInfoFromInviteCode($code: String!) {
    getTeamInfoFromInviteCode(code: $code) {
      name
      uid
      approvedDomains
      isInviteLinkEnabled
      inviterEmail
      inviterDisplayName
      inviterPhotoUrl
      teamAcceptingInvites
    }
  }
`;
