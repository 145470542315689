import { deviceDetect, isMacOs, isWindows } from 'react-device-detect';

enum OperatingSystem {
  Mac = 'macOS',
  Windows = 'Windows',
  Linux = 'Linux',
  Unknown = '',
}

/** Detects the current Operating System */
const detectOS = (): OperatingSystem => {
  if (isMacOs) {
    return OperatingSystem.Mac;
  }
  if (isWindows) {
    return OperatingSystem.Windows;
  }

  // react-device-detect does not expose an `isLinux` constant, so we need to fall back to `deviceDetect`.
  const deviceInfo = deviceDetect(undefined);
  if (deviceInfo?.osName?.toLowerCase() === 'linux') {
    return OperatingSystem.Linux;
  }

  return OperatingSystem.Unknown;
};

/** Returns a version of the `OperatingSystem` that should be displayed within the download dropdown. */
function displayString(operatingSystem: OperatingSystem): String {
  if (operatingSystem === OperatingSystem.Linux) {
    return 'Linux x64';
  }
  return operatingSystem;
}

export { OperatingSystem, detectOS, displayString };
