import { gql } from '@apollo/client';
import { Inviter } from '../../join-team/InviterHeading';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';

export interface TeamInfoFromEmailInviteCodeData {
  name: string;
  uid: string;
  inviter: Inviter;
  inviteeEmail: string;
  expired: boolean;
  teamAcceptingInvites: boolean;
}

export interface TeamInfoFromEmailInviteCodeVariables {
  emailAuthCode: string | undefined;
}

export default (variables: TeamInfoFromEmailInviteCodeVariables) => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      query GetTeamInfoFromEmailInviteCode(
        $input: TeamInfoFromInviteEmailCodeInput!
        $requestContext: RequestContext!
      ) {
        teamInfoFromEmailInviteCode(
          input: $input
          requestContext: $requestContext
        ) {
          ... on TeamInfoFromInviteEmailCodeOutput {
            emailInvite {
              email
              expired
            }
            publicTeamInfo {
              name
              uid
              inviterEmail
              inviterDisplayName
              inviterPhotoUrl
              teamAcceptingInvites
            }
          }
        }
      }
    `;

    const vars = {
      input: {
        code: variables.emailAuthCode,
      },
      requestContext: getRequestContext(),
    };

    const parseData = (data: any): TeamInfoFromEmailInviteCodeData => {
      return {
        name: data?.teamInfoFromEmailInviteCode?.publicTeamInfo?.name,
        uid: data?.teamInfoFromEmailInviteCode?.publicTeamInfo?.uid,
        inviter: {
          email:
            data?.teamInfoFromEmailInviteCode?.publicTeamInfo?.inviterEmail,
          name:
            data?.teamInfoFromEmailInviteCode?.publicTeamInfo
              ?.inviterDisplayName,
          photoUrl:
            data?.teamInfoFromEmailInviteCode?.publicTeamInfo?.inviterPhotoUrl,
        },
        inviteeEmail: data?.teamInfoFromEmailInviteCode?.emailInvite?.email,
        expired: data?.teamInfoFromEmailInviteCode?.emailInvite?.expired,
        teamAcceptingInvites:
          data?.teamInfoFromEmailInviteCode?.publicTeamInfo
            ?.teamAcceptingInvites,
      };
    };

    return { query, variables: vars, parseData };
  }

  const query = gql`
    query GetTeamInfoFromEmailInviteCode(
      $input: GetTeamInfoFromEmailInviteCodeInput!
    ) {
      getTeamInfoFromEmailInviteCode(input: $input) {
        name
        uid
        emailInvitation {
          inviteeEmail
          expired
        }
        inviterEmail
        inviterDisplayName
        inviterPhotoUrl
        teamAcceptingInvites
      }
    }
  `;

  const vars = {
    input: {
      emailAuthCode: variables.emailAuthCode,
    },
  };

  const parseData = (data: any): TeamInfoFromEmailInviteCodeData => {
    return {
      name: data?.getTeamInfoFromEmailInviteCode?.name,
      uid: data?.getTeamInfoFromEmailInviteCode?.uid,
      inviter: {
        email: data?.getTeamInfoFromEmailInviteCode?.inviterEmail,
        name: data?.getTeamInfoFromEmailInviteCode?.inviterDisplayName,
        photoUrl: data?.getTeamInfoFromEmailInviteCode?.inviterPhotoUrl,
      },
      inviteeEmail:
        data?.getTeamInfoFromEmailInviteCode?.emailInvitation?.inviteeEmail,
      expired: data?.getTeamInfoFromEmailInviteCode?.emailInvitation?.expired,
      teamAcceptingInvites:
        data?.getTeamInfoFromEmailInviteCode?.teamAcceptingInvites,
    };
  };

  return { query, variables: vars, parseData };
};
