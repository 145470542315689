import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';

export interface UserForUserDataView {
  email: string;
  teamName: string;
  adminUid: string;
  members: { email: string }[];
}

export default () => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      query GetUser($requestContext: RequestContext!) {
        user(requestContext: $requestContext) {
          ... on UserOutput {
            user {
              profile {
                email
              }
              workspaces {
                teams {
                  name
                  adminUid
                  members {
                    email
                  }
                }
              }
            }
          }
        }
      }
    `;

    const variables = {
      requestContext: getRequestContext(),
    };

    const parseData = (data: any): UserForUserDataView => {
      return {
        email: data?.user?.user?.profile?.email,
        teamName: data?.user?.user?.workspaces[0]?.teams[0]?.name,
        adminUid: data?.user?.user?.workspaces[0]?.teams[0]?.adminUid,
        members: data?.user?.user?.workspaces[0]?.teams[0]?.members,
      };
    };

    return { query, variables, parseData };
  }

  const query = gql`
    query GetUser {
      user {
        email
        teams {
          name
          creatorFirebaseUid
          members {
            email
          }
        }
      }
    }
  `;

  const parseData = (data: any): UserForUserDataView => {
    return {
      email: data?.user?.email,
      teamName: data?.user?.teams[0]?.name,
      adminUid: data?.user?.teams[0]?.creatorFirebaseUid,
      members: data?.user?.teams[0]?.members,
    };
  };

  return { query, variables: {}, parseData };
};
