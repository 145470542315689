import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Helmet from 'react-helmet';
import { DotLottiePlayer } from '@dotlottie/react-player';
import lottieJson from './assets/get_warp_animation.lottie';
import Toast from './Toast';
import { ReactComponent as WarpLogoWithText } from './assets/warp_logo_with_text.svg';
import { ReactComponent as DownloadCanaryLogo } from './assets/download_canary.svg';

import './download.css';
import { warpCanaryPageEnabled } from './utils/features';
import useSearchQuery from './utils/useSearchQuery';
import DownloadDropdown from './download/DownloadDropdown';
import { detectOS, OperatingSystem } from './download/operatingSystem';

export interface DownloadPageProps {
  /**
   * Whether to immediately start a download as soon as this React component is mounted.
   * A download is automatically started if a package format is explicitly requested to be downloaded _or_ if the user
   * is on Mac (in which case we download the .DMG, since there is only one possible package to download).
   */
  canAutoStartDownload: boolean;
}

/** Returns the path to download the requested package format. */
function computeDownloadPath(
  anonymousId: string | null,
  packageFormat: string | null
): string {
  const downloadParams = new URLSearchParams();
  if (anonymousId) {
    downloadParams.append('code', anonymousId);
  }

  if (packageFormat) {
    downloadParams.append('package', packageFormat);
  }

  return `/download?${downloadParams.toString()}`;
}

const DownloadPage = ({ canAutoStartDownload }: DownloadPageProps) => {
  // Set up Toast
  const [showToast, setShowToast] = useState(false);
  const hideToast = () => {
    setShowToast(false);
  };
  const query = useSearchQuery();
  const packageFormat = query.get('package');

  const { invite_code: inviteCode } = useParams<{ invite_code: string }>();

  const anonymousId = query.get('code');

  window.rudderanalytics.track('Visited Download page', {
    inviteCode,
    anonymousId,
  });

  const os = detectOS();

  const autoStartDownload =
    canAutoStartDownload && (!!packageFormat || os === OperatingSystem.Mac);

  const downloadPath = computeDownloadPath(anonymousId, packageFormat);

  // The inner content of the download page. If autodownload was requested, we show text telling the user that they can
  // click a link to download the app if the autodownload didn't work. Otherwise, we show the new download dropdown
  // (which allows downloading of linux builds) if linux is enabled or a simple Mac download button if linux support is
  // disabled.
  const innerDownloadContent = autoStartDownload ? (
    <p className="font-main" id="download-start">
      Your download will start automatically. If it didn&lsquo;t start, click{' '}
      <a href={downloadPath}>here</a>.
    </p>
  ) : (
    <DownloadDropdown />
  );

  const downloadTitleText = "You're about to get Warp";

  return (
    <>
      {autoStartDownload ? (
        <Helmet>
          <meta httpEquiv="refresh" content={`1; URL='${downloadPath}'`} />
        </Helmet>
      ) : (
        <></>
      )}

      <div className="download-background">
        <div className="download-container">
          <div className="download-blurb-and-button">
            <a
              href="https://www.warp.dev"
              id="download-button"
              className="warp-logo-with-text"
            >
              <WarpLogoWithText title="Warp Logo" />
            </a>
            <h1>{downloadTitleText}</h1>
            <div className="download-invite-container">
              {innerDownloadContent}
              <p className="font-main">
                <a href="https://warp.dev/windows-terminal">Sign up</a> to join
                the Windows waitlist.
              </p>
              {warpCanaryPageEnabled() && (
                <>
                  <hr className="download-divider" />
                  <div className="download-canary font-main">
                    <DownloadCanaryLogo />
                    <div>
                      Want to help us make Warp better? Try our{' '}
                      <a href="/canary">nightly Warp Canary build.</a>
                    </div>
                  </div>
                </>
              )}

              <Toast show={showToast} hideToast={hideToast} />
            </div>
          </div>
          <DotLottiePlayer src={lottieJson} autoplay loop />
        </div>
      </div>
    </>
  );
};

export default DownloadPage;
