interface FeatureFlags {
  REACT_APP_ENABLE_SHARE_VIA_LINK: boolean;
  REACT_APP_ENABLE_GRAPHQL_V2_API: boolean;
}

const FEATURE_FLAGS: FeatureFlags = {
  REACT_APP_ENABLE_SHARE_VIA_LINK:
    process.env.REACT_APP_ENABLE_SHARE_VIA_LINK === 'true',
  REACT_APP_ENABLE_GRAPHQL_V2_API:
    process.env.REACT_APP_ENABLE_GRAPHQL_V2_API === 'true',
};

function getDefaultScheme(): string {
  return process.env.REACT_APP_DEFAULT_SCHEME!.trim();
}

function isProd(): Boolean {
  return process.env.REACT_APP_DEFAULT_SCHEME === 'warp';
}

function isStaging(): Boolean {
  return process.env.REACT_APP_DEFAULT_SCHEME === 'warpdev';
}

export { getDefaultScheme, isProd, isStaging, FEATURE_FLAGS };
