import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';

interface GetOrganizationVariables {
  email: string;
}

export default (variables: GetOrganizationVariables) => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      query organization(
        $input: OrganizationInput!
        $requestContext: RequestContext!
      ) {
        organization(input: $input, requestContext: $requestContext) {
          ... on OrganizationOutput {
            organizationId
            ssoEnabled
          }
        }
      }
    `;

    const vars = {
      input: {
        email: variables.email,
      },
      requestContext: getRequestContext(),
    };

    const parseData = (data: any) => {
      return data?.organization;
    };

    return { query, variables: vars, parseData };
  }

  const query = gql`
    query getOrganization($input: GetOrganizationInput!) {
      getOrganization(input: $input) {
        organizationId
        ssoEnabled
      }
    }
  `;

  const vars = {
    input: {
      email: variables.email,
    },
  };

  const parseData = (data: any) => {
    return data?.getOrganization;
  };

  return { query, variables: vars, parseData };
};
