import { gql } from '@apollo/client';

export default gql`
  mutation JoinTeamWithTeamDiscovery(
    $teamUid: ID!
    $entrypoint: TeamDiscoveryEntrypoint!
  ) {
    joinTeamWithTeamDiscovery(
      input: { teamUid: $teamUid, entrypoint: $entrypoint }
    ) {
      ... on JoinTeamWithTeamDiscoverySuccess {
        success
      }
      ... on UserFacingError {
        error {
          message
        }
      }
    }
  }
`;
