import React from 'react';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
} from '../modal-container/ModalContainerButton';
import { WarpOnWebUnsupported } from './requirements';
import DesktopRedirect from '../utils/DesktopRedirect';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';

type Props = {
  status: WarpOnWebUnsupported;
  redirectMessage: string;
  appLaunchUrl?: string;
};

/**
 * A modal that's shown if Warp on Web is unsupported.
 *
 * It's similar to the `FullPageModal` used for desktop redirects, but with a more prominent error message.
 */
export default function UnsupportedModal({
  status,
  redirectMessage,
  appLaunchUrl,
}: Props) {
  const openButton = appLaunchUrl && (
    <ModalContainerButton
      content={<>Open on Desktop</>}
      treatment={ModalContainerButtonTreatment.FullWidth}
      accent={ModalContainerButtonAccent.Primary}
      href={appLaunchUrl}
    />
  );

  return (
    <>
      {appLaunchUrl && <DesktopRedirect url={appLaunchUrl} />}
      <ModalContainer iconType={ModalContainerIconType.Alert}>
        <ModalContainerHeader>{redirectMessage}</ModalContainerHeader>
        <ModalContainerBody>
          <p style={{ margin: 0 }}>
            {status.error}{' '}
            {status.supportLink && (
              <ModalContainerButton
                treatment={ModalContainerButtonTreatment.Inline}
                content={<>Help</>}
                href={status.supportLink}
                openInNewTab
              />
            )}
          </p>
          {openButton}
        </ModalContainerBody>
      </ModalContainer>
    </>
  );
}

UnsupportedModal.defaultProps = {
  appLaunchUrl: null,
};
