import { gql } from '@apollo/client';

export default gql`
  mutation CheckAndRecordObjectAccess($uid: ID!) {
    checkAndRecordObjectAccess(input: { objectUID: $uid }) {
      ... on CheckAndRecordObjectAccessOutput {
        objectName
        isTrashed
        folderPrimaryObject {
          ... on NewNotebook {
            __typename
            metadata {
              UID
            }
          }
          ... on Workflow {
            __typename
            metadata {
              UID
            }
          }
        }
      }
    }
  }
`;
