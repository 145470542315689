import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';

interface SendRequestObjectAccessEmailVariables {
  uid: string;
}

export default (variables: SendRequestObjectAccessEmailVariables) => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      mutation SendRequestObjectAccessEmail(
        $input: SendRequestObjectAccessEmailInput!
        $requestContext: RequestContext!
      ) {
        sendRequestObjectAccessEmail(
          input: $input
          requestContext: $requestContext
        ) {
          ... on SendRequestObjectAccessEmailOutput {
            success
          }
        }
      }
    `;

    const vars = {
      input: {
        uid: variables.uid,
      },
      requestContext: getRequestContext(),
    };

    const parseData = (data: any) => {
      return data?.sendRequestObjectAccessEmail?.success;
    };

    return { query, variables: vars, parseData };
  }

  const query = gql`
    mutation SendRequestObjectAccessEmail($uid: ID!) {
      sendRequestObjectAccessEmail(input: { objectUID: $uid }) {
        success
      }
    }
  `;

  const vars = {
    uid: variables.uid,
  };

  const parseData = (data: any) => {
    return data?.sendRequestObjectAccessEmail?.success;
  };

  return { query, variables: vars, parseData };
};
