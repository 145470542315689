import React, { FormEvent, useState } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
  ModalContainerButtonType,
} from '../modal-container/ModalContainerButton';
import SendVerificationEmail from '../graphql/mutations/SendVerificationEmail';
import SubmitVerificationCode from '../graphql/mutations/SubmitVerificationCode';

export interface DomainNotApprovedProps {
  inviteCode: string;
  teamApprovedDomains: string[] | undefined;
  setHasApprovedDomain: (value: boolean) => void;
  setJoinedSuccessfully: (value: boolean) => void;
}

const DomainNotApproved = ({
  inviteCode,
  teamApprovedDomains,
  setHasApprovedDomain,
  setJoinedSuccessfully,
}: DomainNotApprovedProps) => {
  const [authEmailInput, setAuthEmailInput] = useState('');
  const [authCodeInput, setAuthCodeInput] = useState('');
  const [authEmailSent, setAuthEmailSent] = useState(false);

  const client = useApolloClient();

  const [sendVerificationEmail] = useMutation(SendVerificationEmail, {
    client,
    variables: { inviteCode, email: authEmailInput },
    onCompleted(data) {
      if (data?.sendVerificationEmailWithCode) {
        setAuthEmailSent(true);
        setAuthCodeInput('');
      }
    },
    onError(err) {
      err.graphQLErrors.forEach((error) => {
        // eslint-disable-next-line no-alert
        alert(`Failed to send verification email. ${error.message}`);
      });
    },
  });

  const [submitVerificationCode] = useMutation(SubmitVerificationCode, {
    client,
    variables: { inviteCode, authCode: authCodeInput },
    onCompleted(data) {
      if (data?.joinTeamWithAuthCode) {
        setHasApprovedDomain(true);
        setJoinedSuccessfully(true);
      }
    },
    onError(err) {
      err.graphQLErrors.forEach((error) => {
        // eslint-disable-next-line no-alert
        alert(`Verification failed: ${error.message}`);
      });
    },
  });

  const onEmailSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendVerificationEmail();
  };

  const onAuthCodeSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitVerificationCode();
  };

  const onTryAgain = () => {
    setAuthEmailSent(false);
  };

  const domainsListRegex = teamApprovedDomains?.join('|');
  const approvedEmailRegex = `.+@(${domainsListRegex})`;

  return (
    <ModalContainer iconType={ModalContainerIconType.Warning}>
      <ModalContainerHeader>
        You&apos;ve received an invite to a domain-restricted team @
        {teamApprovedDomains?.join(', @')}.
      </ModalContainerHeader>
      <ModalContainerBody>
        {authEmailSent ? (
          <>
            <div>
              A verification code has been sent to {authEmailInput}! Copy and
              paste the code below to join the team.
            </div>
            <form
              id="signup-email-form"
              onSubmit={onAuthCodeSubmit}
              style={{ gap: '16px' }}
            >
              <div className="font-weight-600 text-white">
                Verification Code
              </div>
              <input
                type="text"
                id="signup-email-input"
                placeholder="Verification Code"
                value={authCodeInput}
                required
                onChange={(e) => setAuthCodeInput(e.target.value)}
              />
              <ModalContainerButton
                treatment={ModalContainerButtonTreatment.FullWidth}
                accent={ModalContainerButtonAccent.Primary}
                buttonType={ModalContainerButtonType.Submit}
                content={<>Join Team</>}
              />
              <div>
                Not seeing an email?{' '}
                <ModalContainerButton
                  treatment={ModalContainerButtonTreatment.Inline}
                  content={<>Try again.</>}
                  onClickFunction={onTryAgain}
                />
              </div>
            </form>
          </>
        ) : (
          <>
            <div>
              You can submit an approved email and receive a verification code
              to join the team:
            </div>
            <form
              id="signup-email-form"
              onSubmit={onEmailSubmit}
              style={{ gap: '16px' }}
            >
              <div className="font-weight-600 text-white">Email</div>
              <input
                type="email"
                id="signup-email-input"
                placeholder="Your email address"
                value={authEmailInput}
                required
                pattern={approvedEmailRegex}
                title="Email is not from an approved domain."
                onChange={(e) => setAuthEmailInput(e.target.value)}
              />
              <ModalContainerButton
                treatment={ModalContainerButtonTreatment.FullWidth}
                accent={ModalContainerButtonAccent.Primary}
                buttonType={ModalContainerButtonType.Submit}
                content={<>Send Code</>}
              />
            </form>
          </>
        )}
      </ModalContainerBody>
    </ModalContainer>
  );
};

export default DomainNotApproved;
