import { isProd } from './env';

export function warpCanaryPageEnabled(): Boolean {
  return !isProd();
}

export function makePrettierNotComplainAboutASingleExport(): Boolean {
  // This function is pointless, but if we only export a single function,
  // prettier forces us to make it a default export, which doesn't make any
  // sense here.

  return true;
}
