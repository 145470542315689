import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';

export interface JoinTeamWithInviteCodeVariables {
  code: string | undefined;
}

export default (variables: JoinTeamWithInviteCodeVariables) => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      mutation JoinTeamWithInviteCode(
        $input: JoinTeamWithInviteCodeInput!
        $requestContext: RequestContext!
      ) {
        joinTeamWithInviteCode(input: $input, requestContext: $requestContext) {
          ... on JoinTeamWithInviteCodeOutput {
            success
          }
        }
      }
    `;

    const vars = {
      input: {
        code: variables.code,
      },
      requestContext: getRequestContext(),
    };

    const parseData = (data: any) => {
      return data?.joinTeamWithInviteCode?.success;
    };

    return { query, variables: vars, parseData };
  }

  const query = gql`
    mutation JoinTeamWithInviteCode($code: String!) {
      joinTeamWithInviteCode(code: $code)
    }
  `;

  const vars = {
    code: variables.code,
  };

  const parseData = (data: any) => {
    return data?.joinTeamWithInviteCode;
  };

  return { query, variables: vars, parseData };
};
