import { gql } from '@apollo/client';
import { FEATURE_FLAGS } from '../../utils/env';
import getRequestContext from '../get-request-context';

interface JoinTeamWithTeamDiscoveryVariables {
  teamUid: string;
  entrypoint: string;
}

export default (variables: JoinTeamWithTeamDiscoveryVariables) => {
  if (FEATURE_FLAGS.REACT_APP_ENABLE_GRAPHQL_V2_API) {
    const query = gql`
      mutation JoinTeamWithTeamDiscovery(
        $input: JoinTeamWithTeamDiscoveryInput!
        $requestContext: RequestContext!
      ) {
        joinTeamWithTeamDiscovery(
          input: $input
          requestContext: $requestContext
        ) {
          ... on JoinTeamWithTeamDiscoveryOutput {
            success
          }
        }
      }
    `;

    const vars = {
      input: {
        teamUid: variables.teamUid,
        entrypoint: variables.entrypoint,
      },
      requestContext: getRequestContext(),
    };

    const parseData = (data: any) => {
      return data?.joinTeamWithTeamDiscovery?.success;
    };

    return { query, variables: vars, parseData };
  }

  const query = gql`
    mutation JoinTeamWithTeamDiscovery(
      $teamUid: ID!
      $entrypoint: TeamDiscoveryEntrypoint!
    ) {
      joinTeamWithTeamDiscovery(
        input: { teamUid: $teamUid, entrypoint: $entrypoint }
      ) {
        ... on JoinTeamWithTeamDiscoverySuccess {
          success
        }
      }
    }
  `;

  const vars = {
    teamUid: variables.teamUid,
    entrypoint: variables.entrypoint,
  };

  const parseData = (data: any) => {
    return data?.joinTeamWithTeamDiscovery?.success;
  };

  return { query, variables: vars, parseData };
};
