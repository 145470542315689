/** Available set of packages that can be downloaded. */
import { OperatingSystem } from './operatingSystem';

class PackageFormat {
  /** The query parameter used to download this format. */
  queryParameter: string;

  /** The display format to be used when rendering the item within the dropdown. */
  display: string;

  /** The backing operating system for which this package is to be downloaded. */
  operatingSystem: OperatingSystem;

  /** The subtitle text, if any, that is rendered below the display string within the dropdown item. */
  subtitle?: string;

  constructor(
    queryParameter: string,
    display: string,
    operatingSystem: OperatingSystem,
    subtitle?: string
  ) {
    this.queryParameter = queryParameter;
    this.display = display;
    this.subtitle = subtitle;
    this.operatingSystem = operatingSystem;
  }
}

const MacDmg = new PackageFormat('dmg', '', OperatingSystem.Mac);
const LinuxDeb = new PackageFormat('deb', '.deb', OperatingSystem.Linux);
const LinuxRpm = new PackageFormat('rpm', '.rpm', OperatingSystem.Linux);
const LinuxPkg = new PackageFormat(
  'pacman',
  '.pkg.tar.zst',
  OperatingSystem.Linux,
  'Arch Linux'
);
const LinuxAppImage = new PackageFormat(
  'appimage',
  'AppImage',
  OperatingSystem.Linux
);

/** Set of package formats that are for Linux. */
const linuxPackages = [LinuxDeb, LinuxRpm, LinuxPkg, LinuxAppImage];

export {
  PackageFormat,
  linuxPackages,
  MacDmg,
  LinuxDeb,
  LinuxPkg,
  LinuxRpm,
  LinuxAppImage,
};
