import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { User } from './AuthenticatedView';
import createRedirectAfterLoginPath from './utils/redirectAfterLogin';
import FullPageModal from './FullPageModal';
import WasmView from './WasmView';
import LoadingScreen from './loading-screen/LoadingScreen';

interface SessionShareViewProps {
  user: User;
}

function getAppLaunchURL(sessionID: string, pwd: string): string {
  return `${process.env.REACT_APP_DEFAULT_SCHEME}://shared_session/${sessionID}?pwd=${pwd}`;
}

function getSessionExistenceCheckURL(
  sessionID: string,
  pwd: string | null
): string {
  return `${process.env.REACT_APP_SESSION_SHARING_ROOT_URL}/sessions/${sessionID}?pwd=${pwd}`;
}

const SessionShareView = ({ user }: SessionShareViewProps) => {
  // TODO figure out the correct analytics
  if (user?.isTelemetryEnabled) {
    window.rudderanalytics.track('Visited join session view');
  }
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const params = useParams<{ id: string }>();

  const sessionID = params.id;
  const sessionPwd = queryParams.get('pwd');

  const [isLoading, setIsLoading] = useState(true);
  const [sessionExists, setSessionExists] = useState(false);
  const [existenceCheckError, setExistenceCheckError] = useState('');

  useEffect(() => {
    if (sessionID != null && sessionPwd != null) {
      fetch(getSessionExistenceCheckURL(sessionID, sessionPwd), {
        method: 'HEAD',
      })
        .then((response) => {
          setSessionExists(response.status === 204);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setExistenceCheckError(
            `Error validating session: ${error?.message.trim()}`
          );
        });
    } else {
      setIsLoading(false);
    }
  }, [sessionID, sessionPwd]);

  if (!user) {
    return (
      <Navigate
        to={createRedirectAfterLoginPath(`${pathname}${search}`)}
        replace
      />
    );
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (existenceCheckError !== '') {
    return <FullPageModal error={existenceCheckError} />;
  }

  if (!sessionID || !sessionPwd || !sessionExists) {
    return <FullPageModal error="Session could not be found." />;
  }

  // Otherwise just return the wasm component
  return (
    <WasmView
      splashPageMessage="Open Warp to join this session."
      appLaunchUrl={getAppLaunchURL(sessionID, sessionPwd)}
    />
  );
};

export default SessionShareView;
