import React from 'react';
import ModalContainer, {
  ModalContainerIconType,
} from '../modal-container/ModalContainer';
import ModalContainerHeader from '../modal-container/ModalContainerHeader';
import ModalContainerBody from '../modal-container/ModalContainerBody';
import ModalContainerButton, {
  ModalContainerButtonAccent,
  ModalContainerButtonTreatment,
  ModalContainerButtonType,
} from '../modal-container/ModalContainerButton';
import InviterHeading, { Inviter } from './InviterHeading';

export interface WrongEmailAddressProps {
  inviterFromInviteCode: Inviter | undefined;
  inviteeEmailFromInviteCode: string | undefined;
  logOut: () => void;
  teamNameFromInviteCode: string | undefined;
  userEmail: string | null;
}

const WrongEmailAddress = ({
  inviterFromInviteCode,
  inviteeEmailFromInviteCode,
  logOut,
  teamNameFromInviteCode,
  userEmail,
}: WrongEmailAddressProps) => {
  return (
    <ModalContainer iconType={ModalContainerIconType.Warning}>
      <ModalContainerHeader>
        <InviterHeading
          inviter={inviterFromInviteCode}
          teamName={teamNameFromInviteCode}
        />
      </ModalContainerHeader>
      <ModalContainerBody>
        <div>
          This invitation is for{' '}
          <span className="font-weight-600 text-white">
            {inviteeEmailFromInviteCode}
          </span>
          .
        </div>
        <div>
          You are logged in as{' '}
          <span className="font-weight-600 text-white">{userEmail}</span>.
        </div>
        <div>
          In order to join the team, you must first log in with the
          corresponding email.
        </div>
        <ModalContainerButton
          treatment={ModalContainerButtonTreatment.FullWidth}
          buttonType={ModalContainerButtonType.Button}
          accent={ModalContainerButtonAccent.Primary}
          onClickFunction={logOut}
          content={<>Log out</>}
        />
      </ModalContainerBody>
    </ModalContainer>
  );
};

export default WrongEmailAddress;
