import { gql } from '@apollo/client';

export default gql`
  mutation CreateTeam($input: CreateTeamInput!) {
    createTeamV2(input: $input) {
      __typename
      ... on CreateTeamOutput {
        team {
          uid
          creatorFirebaseUid
          billingMetadata {
            customerType
          }
          members {
            email
            firebase_uid
          }
        }
      }
      ... on UserFacingError {
        error {
          message
        }
      }
    }
  }
`;
