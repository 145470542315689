import React from 'react';
import { ModalContainerIconType } from '../modal-container/ModalContainer';
import './upgrade.css';
import './request-upgrade-overlay.css';
import TeamPlanDescription from './TeamPlanDescription';
import PlanCostCalculation from './PlanCostCalculation';
import CheckoutButton from './CheckoutButton';
import {
  StripeSubscriptionInterval,
  StripeSubscriptionPlan,
} from './UpgradeEnums';
import IntervalToggle from './IntervalToggle';
import UpgradeModalContainer from './UpgradeModalContainer';

export interface OnlyTeamPlansViewProps {
  isAdmin: boolean;
  overlay?: React.ReactNode;
  interval: StripeSubscriptionInterval;
  numMembers: number;
  monthlyButtonFunction: () => void;
  yearlyButtonFunction: () => void;
  continueButtonFunction: () => void;
}

export default function OnlyTeamPlansView({
  isAdmin,
  overlay,
  interval,
  numMembers,
  monthlyButtonFunction,
  yearlyButtonFunction,
  continueButtonFunction,
}: OnlyTeamPlansViewProps) {
  return (
    <div className="background">
      {overlay && <div className="overlay">{overlay}</div>}
      <div className="upgrade-background">
        <div className="upgrade-modal-containers-row">
          <UpgradeModalContainer
            iconType={ModalContainerIconType.Logo}
            header={<>Upgrade to the Team plan</>}
          >
            <TeamPlanDescription />
            <IntervalToggle
              inModal
              interval={interval}
              monthlyOnClickFunction={monthlyButtonFunction}
              yearlyOnClickFunction={yearlyButtonFunction}
            />
            <PlanCostCalculation
              interval={interval}
              numMembers={numMembers}
              plan={StripeSubscriptionPlan.TEAM}
            />
            <CheckoutButton
              label={isAdmin ? 'Continue' : 'Ask your Warp admin to upgrade'}
              onClickFunction={continueButtonFunction}
            />
          </UpgradeModalContainer>
        </div>
      </div>
    </div>
  );
}

OnlyTeamPlansView.defaultProps = {
  overlay: undefined,
};
